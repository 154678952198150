import React, {useEffect, useState, useRef, Fragment} from 'react'
import {connect} from 'react-redux'
import {Navigate, useParams} from 'react-router-dom'

import {Table, TableEntry, Button, Icon, Input, Card, Select, Switch} from '@lazarusai/forms-ui-components'
import ModularMetrics from './ModularMetrics'

import Helpers from '../Helpers'
import {getUsers} from '../actions/getUsers'
import {storePayload} from '../actions/storePayload'
import endpoints from '../endpoints.json'
import blockedEmails from '../blockedEmails.json'
import '../styles/SubOrgView.css'
import {getSubOrganizationDetails} from '../actions/getSubOrganizationDetails'
import {setSubOrgModelAccessPricing} from '../actions/setSubOrgModelAccessPricing'
import {setSubOrgLimits} from '../actions/setSubOrgLimits'
import {getSubOrganizationAuthKey} from '../actions/getSubOrganizationAuthKey'
import {resetSubOrganizationAuthKey} from '../actions/resetSubOrganizationAuthKey'
import {getSubOrgBillingHistory} from '../actions/getSubOrgBillingHistory'
import {updateSubOrgCreatePermission} from '../actions/updateSubOrgCreatePermission'
import LimitInputs from './LimitInputs'
import {getSubOrganizationLimits} from '../actions/getSubOrganizationLimits'
import PermissionSideTab from './PermissionSideTab'
import {setSubOrgVKGValues} from '../actions/setSubOrgVKGValues'

function SubOrgView(props) {
  const [subOrgUserEntries, setSubOrgUserEntries] = useState([])
  const [permissionsModalShowing, setPermissionsModalShowing] = useState(false)
  const [permissionsID, _setPermissionsID] = useState(null)
  const permissionsIDRef = useRef(null)

  function setPermissionsID(val) {
    _setPermissionsID(val)
    permissionsIDRef.current = val
  }

  const [permissionsEmail, setPermissionsEmail] = useState(null)
  const [permissionsUser, setPermissionsUser] = useState(null)
  const [inviteEmail, setInviteEmail] = useState('')
  const [subOrgModelAccess, setSubOrgModelAccess] = useState([])
  const [subOrgModelPricing, setSubOrgModelPricing] = useState({})
  const [subOrgLimits, setSubOrgLimits] = useState({})
  const [isSubOrgAuthKeyShowing, setIsSubOrgAuthKeyShowing] = useState(false)
  const [isOrgIdCopied, setIsOrgIdCopied] = useState(false)
  const [isAuthKeyCopied, setIsAuthKeyCopied] = useState(false)
  const [subOrgCanCreateSubOrg, setSubOrgCanCreateSubOrg] = useState(undefined)
  const [confirmResetAuthKey, setConfirmResetAuthKey] = useState(false)
  const [subOrgVkgDomain, setSubOrgVkgDomain] = useState('')
  const [subOrgLicenseKey, setSubOrgLicenseKey] = useState('')
  const [hasFullVKGAccess, setHasFullVKGAccess] = useState(false)
  const [selectedVKGs, setSelectedVKGs] = useState([])
  const [vkgSearchString, setVKGSearchString] = useState('')
  const [subOrgCanVKGRead, setSubOrgCanVKGRead] = useState(false)
  const [subOrgCanVKGWrite, setSubOrgCanVKGWrite] = useState(false)
  const [subOrgModelMetrics, setSubOrgModelMetrics] = useState({})

  const hiddenString = '*************'

  const onClickCopyOrgId = () => {
    Helpers.copyToClipBoard(subOrgId, () => {
      setIsOrgIdCopied(true)
    })

    setTimeout(() => {
      setIsOrgIdCopied(false)
    }, 2000)
  }

  const onClickCopyAuthKey = () => {
    Helpers.copyToClipBoard(props.subOrgAuthKey, () => {
      setIsAuthKeyCopied(true)
    })

    setTimeout(() => {
      setIsAuthKeyCopied(false)
    }, 2000)
  }

  function showAuthKey() {
    setIsSubOrgAuthKeyShowing(!isSubOrgAuthKeyShowing)
    if (!props.subOrgAuthKey) {
      // GET AUTH KEY
      props.getSubOrganizationAuthKey(props.orgId, props.authKey, props.user.uid, subOrgId)
    }
  }

  useEffect(() => {
    // Run Info Call
    props.storePayload({
      subOrganizationDetails: null,
      subOrgAuthKey: undefined,
      subOrganizationLimits: {},
    })
    props.getSubOrganizationAuthKey(props.orgId, props.authKey, props.user.uid, subOrgId)
    props.getSubOrganizationDetails(props.orgId, props.authKey, props.user.uid, subOrgId)
    props.getSubOrganizationLimits(subOrgId)
  }, [])

  const isBusinessEmail = (email) => {
    return !blockedEmails.blockedEmails.some((emailProvider) => email.includes(emailProvider))
  }

  function onChangeForm(e) {
    if (!isBusinessEmail(e.target.value)) {
      props.storePayload({
        userMessage: 'Users must have business emails',
        notificationType: 2,
        notificationIcon: 'warning',
      })
    }
    setInviteEmail(e.target.value)
  }

  function onClickSendInvite(e) {
    e.preventDefault()
    setPermissionsID(inviteEmail)
    setPermissionsEmail(inviteEmail)
    /* clear user if stored from existing user permission change
      (same modal rendered for invite and existing user permission change)
    */
    setPermissionsUser(null)
    setPermissionsModalShowing(true)
  }

  useEffect(() => {
    if (props.subOrganizationDetails?.userRoster) {
      setSubOrgUserEntries(
          Object.keys(props.subOrganizationDetails?.userRoster || {})
              .map((userId) => {
                return (
                  <TableEntry
                    key={`user-row-${userId}`}
                    content={[
                      <span key='email' className='text-bright-blue'>
                        {props.subOrganizationDetails.userRoster[userId]?.email || ''}
                      </span>,
                      <span key='date'>{props.subOrganizationDetails.userRoster[userId]?.datetimeCreated}</span>,
                      <Button
                        key='role'
                        theme={props.theme}
                        className='role-button capitalize'
                        type={8}
                        text={props.subOrganizationDetails.userRoster[userId]?.['role']}
                        icon={<Icon icon='arrow-right-outline' />}
                        iconPosition='right'
                        iconJustify='edge'
                        disabled={userId === props.user?.uid}
                        onClick={() => {
                          setPermissionsID(userId)
                          setPermissionsEmail(props.subOrganizationDetails.userRoster[userId]?.email)
                          setPermissionsUser(props.subOrganizationDetails.userRoster[userId])
                          setPermissionsModalShowing(true)
                        }}
                      ></Button>,
                    ]}
                  />
                )
              }),
      )
    }
    initializeModelControlState()
    initializeLimitState()
    initializeCanCreateSubOrg()
    initializeVKGValues()
  }, [props.subOrganizationDetails])

  function initializeModelControlState(hardReset = false) {
    if (props.subOrganizationDetails?.models && (!subOrgModelAccess.length || hardReset)) {
      const models = structuredClone(props.subOrganizationDetails?.models)
      const modelKeys = Object.keys(models || {})
      if (modelKeys.includes('invoices-with-kvp') &&
        modelKeys.includes('invoices')
      ) {
        models['invoices'] = {
          'is_active': true,
          'last_used': new Date(models['invoices']['last_used']) > new Date(models['invoices-with-kvp']['last_used']) ?
            models['invoices']['last_used']: models['invoices-with-kvp']['last_used'],
          'successful_requests': models['invoices']['successful_requests'] + models['invoices-with-kvp']['successful_requests'],
          'total_failures': models['invoices']['total_failures'] + models['invoices-with-kvp']['total_failures'],
          'total_pages': models['invoices']['total_pages'] + models['invoices-with-kvp']['total_pages'],
          'total_questions': models['invoices']['total_questions'] + models['invoices-with-kvp']['total_questions'],
        }
      }
      setSubOrgModelMetrics(models)
      setSubOrgModelAccess(Object.keys(models || {})
          .filter((modelId) => {
            return (models?.[modelId]?.is_active && props.modelInformation?.[modelId])
          }))
    } else if (!props.subOrganizationDetails?.models) {
      setSubOrgModelAccess([])
    }
    if (props.subOrganizationDetails?.billing?.pricing && (!Object.keys(subOrgModelPricing || {}).length || hardReset)) {
      const pricing = props.subOrganizationDetails?.billing?.pricing
      const priceKeys = Object.keys(pricing || {})
      for (let pIndex = 0; pIndex < priceKeys.length; pIndex++) {
        pricing[priceKeys[pIndex]].isSetting = true
      }
      setSubOrgModelPricing(hardReset ? Object.assign({}, pricing || {}) : Object.assign({}, pricing || {}, subOrgModelPricing))
    } else if (!props.subOrganizationDetails?.billing?.pricing) {
      setSubOrgModelPricing({})
    }
  }

  function initializeVKGValues() {
    if (props.subOrganizationDetails) {
      setSubOrgVkgDomain(props.subOrganizationDetails?.['vkgDomain'] || '')
      setSubOrgLicenseKey(props.subOrganizationDetails?.['licenseKey'] || '')
      setHasFullVKGAccess(['read', 'write'].some((perm) => props.subOrganizationDetails?.['vkgAccess']?.vkgs?.includes(perm)))
      setSelectedVKGs(Object.keys(props.subOrganizationDetails?.['vkgAccess'] || {}).filter((vkgId) => vkgId !== 'vkgs'))
      setSubOrgCanVKGRead(Object.keys(props.subOrganizationDetails?.['vkgAccess'] || {}).some((vkgId) => props.subOrganizationDetails?.['vkgAccess']?.[vkgId]?.includes('read')))
      setSubOrgCanVKGWrite(Object.keys(props.subOrganizationDetails?.['vkgAccess'] || {}).some((vkgId) => props.subOrganizationDetails?.['vkgAccess']?.[vkgId]?.includes('write')))
    }
  }

  function getDetailsLimit(hardReset) {
    const newSubOrgLimits = JSON.parse(JSON.stringify(hardReset ? {} : subOrgLimits))
    if (props.subOrganizationDetails?.billing?.creditLimit && !(hardReset ? {} : subOrgLimits)?.creditLimit) {
      newSubOrgLimits.creditLimit = props.subOrganizationDetails?.billing?.creditLimit
      delete newSubOrgLimits.creditLimit.creditUsage
    }
    if (props.subOrganizationDetails?.billing?.pageLimit && !(hardReset ? {} : subOrgLimits)?.pageLimit) {
      newSubOrgLimits.pageLimit = props.subOrganizationDetails?.billing?.pageLimit
      delete newSubOrgLimits.pageLimit.pageUsage
    }
    if (props.subOrganizationDetails?.billing?.modelLimit && !(hardReset ? {} : subOrgLimits)?.modelLimit) {
      newSubOrgLimits.modelLimit = Object.assign({},
          ...Object.keys(props.subOrganizationDetails?.billing?.modelLimit || {})
              .map((modelId) => {
                const retObj = {}
                retObj[modelId] = Object.assign({},
                    ...Object.keys(props.subOrganizationDetails?.billing?.modelLimit?.[modelId] || {})
                        .filter((key) => !key.includes('Usage'))
                        .map((key) => {
                          const retKeyObj = {}
                          retKeyObj[key] = props.subOrganizationDetails?.billing?.modelLimit?.[modelId]?.[key]
                          return retKeyObj
                        }))
                return retObj
              }))
      // pain
    }
    return newSubOrgLimits
  }

  function initializeLimitState(hardReset = false) {
    const newSubOrgLimits = getDetailsLimit(hardReset)
    if (JSON.stringify(newSubOrgLimits) !== JSON.stringify(subOrgLimits)) {
      setSubOrgLimits(newSubOrgLimits)
    }
  }

  function initializeCanCreateSubOrg(hardReset = false) {
    const currentValue = props.subOrganizationDetails?.orgPermissions?.canCreateSubOrg
    if ((currentValue !== undefined && subOrgCanCreateSubOrg === undefined) || hardReset) {
      setSubOrgCanCreateSubOrg(currentValue)
    }
  }

  function changeUserPermissions(permissions, id, currentRole, vkgAccess) {
    // const url = process.env.REACT_APP_URL + 'update/user'
    const url = process.env.REACT_APP_URL + endpoints.updateUser
    const data = {
      userId: id,
      subOrgId: subOrgId,
      permissions: permissions,
      vkgAccess: Helpers.encodeVKGAccess(vkgAccess),
      role: currentRole,
    }
    const headers = {
      userId: props.user.uid,
      orgId: props.orgId,
      authKey: props.authKey,
      subOrgId: subOrgId,
    }
    return Helpers.fetchPostJsonWithStatus(url, data, headers)
  }

  const savePermissions = (value, id, currentRole, vkgAccess) => {
    setPermissionsModalShowing(false)

    if (id === inviteEmail && inviteEmail.length > 0) {
      // const url = process.env.REACT_APP_URL + 'invite'
      const url = process.env.REACT_APP_URL + endpoints.invite
      const data = {
        subOrgId: subOrgId,
        email: inviteEmail,
        role: currentRole.toLowerCase(),
        vkgAccess: Helpers.encodeVKGAccess(vkgAccess),
        permissions: value,
      }
      const headers = {
        userId: props.user.uid,
        orgId: props.orgId,
        authKey: props.authKey,
        subOrgId: subOrgId,
      }
      Helpers.fetchPostJsonWithStatus(url, data, headers)
          .then(async ([resPromise, status]) => {
            const data = await resPromise
            if (status >= 200 && status < 300) {
              props.storePayload({
                userMessage:
        `User was successfully added. They should receive an email with a link to reset their password${data.error ? ': '+Helpers.parseErrorMessages(data.error).join(', ') : '.'}`,
                notificationType: 1,
                notificationIcon: 'check',
              })
              setInviteEmail('')
              props.getSubOrganizationDetails(props.orgId, props.authKey, props.user.uid, subOrgId)
            } else {
              props.storePayload({
                userMessage: `Error inviting user${data.error ? ': '+Helpers.parseErrorMessages(data.error).join(', ') : '.'}`,
                notificationType: 2,
                notificationIcon: 'warning',
              })
            }
          })
          .catch((data) => {
            console.log(data)
            props.storePayload({
              userMessage: 'Error inviting user.',
              notificationType: 2,
              notificationIcon: 'warning',
            })
          })
    } else { // regular permission change
      changeUserPermissions(value, id, currentRole.toLowerCase(), vkgAccess)
          .then(async ([resPromise, status]) => {
            const res = await resPromise
            if (status >= 200 && status < 300) {
              props.storePayload({
                userMessage: `Permissions updated successfully${res.message ? ': '+res.message : '.'}`,
                notificationType: 2,
                notificationIcon: 'check',
              })
              props.getSubOrganizationDetails(props.orgId, props.authKey, props.user.uid, subOrgId)
            } else {
              props.storePayload({
                userMessage: `Error updating permissions${res.message ? ': '+res.message : '.'}`,
                notificationType: 1,
                notificationIcon: 'warning',
              })
            }
          })
          .catch((error) => {
            console.log(error)
            props.storePayload({
              userMessage:
    'Error updating permissions.',
              notificationType: 1,
              notificationIcon: 'warning',
            })
          })
    }
  }

  const {subOrgId} = useParams()
  const hasSubOrgAccess = () => {
    if (props.subOrganizationMetrics && !props.subOrganizationMetrics?.[subOrgId]) {
      return false
    }
    return (subOrgId !== props.orgId) && (props.usersObj[props.user.uid]['role'] === 'admin' || props.usersObj[props.user.uid]['permissions']['controlSubOrgs'])
  }

  const pricingStateToOutput = (pricingState) => {
    return Object.assign({}, ...Object.keys(pricingState || {}).sort()
        .map((modelId) => {
          const modelPrice = JSON.parse(JSON.stringify(pricingState[modelId]))
          delete modelPrice.isSetting
          const output = {}
          output[modelId] = modelPrice
          return output
        }),
    )
  }

  const hasModelAccessOrPricingChanged = (subOrgModelAccess, subOrgModelPricing, subOrganizationDetails) => {
    // Check Model Access
    if (subOrgModelAccess.length !== Object.keys(subOrganizationDetails?.models || {})
        .filter((modelId) => {
          return subOrganizationDetails?.models?.[modelId]?.is_active
        }).length ||
      JSON.stringify(subOrgModelAccess.sort()) !== JSON.stringify(Object.keys(subOrganizationDetails?.models || {}).filter((modelId) => {
        return subOrganizationDetails?.models?.[modelId]?.is_active
      }).sort())
    ) {
      return true
    } else if (JSON.stringify(Object.keys(subOrganizationDetails?.billing?.pricing || {}).sort()) !== JSON.stringify(Object.keys(subOrgModelPricing || {}).sort()) ||
      JSON.stringify(pricingStateToOutput(subOrgModelPricing)) !== JSON.stringify(pricingStateToOutput(subOrganizationDetails?.billing?.pricing))
    ) {
      return true
    }
    return false
  }

  function areVKGAccessesDifferent(aAccess, bAccess) {
    const aKeys = Object.keys(aAccess || {})
    const bKeys = Object.keys(bAccess || {})
    if (aKeys?.length !== bKeys?.length) {
      return true
    }
    let areThereDifferences = false
    for (let kIndex = 0; kIndex < aKeys.length; kIndex++) {
      const key = aKeys[kIndex]
      if (orderPerms(aAccess?.[key]) !== orderPerms(bAccess?.[key])) {
        areThereDifferences = true
      }
    }
    return areThereDifferences
  }

  function orderPerms(permString) {
    return (permString?.split(',') || [])?.sort()?.join(',')
  }

  // eslint-disable-next-line no-unused-vars
  function hasSubOrgVKGInfoChanged(subOrgVkgDomain, subOrgLicenseKey, subOrgVkgAccess) {
    if (areVKGAccessesDifferent(subOrgVkgAccess, (props.subOrganizationDetails?.['vkgAccess'] || {}))) {
      return true
    } else if (subOrgVkgDomain !== (props.subOrganizationDetails?.['vkgDomain'] || '')) {
      return true
    } else if (subOrgLicenseKey !== (props.subOrganizationDetails?.['licenseKey'] || '')) {
      return true
    } else {
      return false
    }
  }

  // Permission Wrapper for org level permission check
  const canChangeSubOrg = (orgDetails) => {
    return orgDetails?.permissions?.canCreateSubOrg || false
  }

  function getBillingHistory() {
    props.storePayload({
      userMessage: 'Getting billing history...',
      notificationType: 1,
      notificationIcon: 'check',
    })
    props.getSubOrgBillingHistory(props.orgId, props.authKey, props.user.uid,
        subOrgId, props.subOrganizationDetails.orgName)
  }

  function doesUserHaveFullAccess() { // check if signed in user has full access
    const allVKGsAccess = props.usersObj?.[props.user.uid]?.vkgAccess?.vkgs || ''
    return ['read', 'write', 'create'].every((perm) => allVKGsAccess.includes(perm))
  }

  function generateVKGAccess(selectedVKGs, subOrgCanVKGRead, subOrgCanVKGWrite, hasFullVKGAccess) {
    if (hasFullVKGAccess && doesUserHaveFullAccess()) {
      if (subOrgCanVKGWrite) {
        return {vkgs: 'read,write,create'}
      } else if (subOrgCanVKGRead) {
        return {vkgs: 'read'}
      } else {
        return {}
      }
    } else {
      const retVKGAccess = {}
      if (subOrgCanVKGWrite) {
        retVKGAccess['vkgs'] = 'create'
      }
      const accessString = `${(subOrgCanVKGRead || subOrgCanVKGWrite) ? 'read': ''}${subOrgCanVKGWrite ? ',write': ''}`
      if (hasFullVKGAccess) {
        const allVKGs = Object.keys(props.graphObj || {})
        for (let sIndex = 0; sIndex < allVKGs.length; sIndex++) {
          retVKGAccess[allVKGs[sIndex]] = accessString
        }
      } else {
        for (let sIndex = 0; sIndex < selectedVKGs.length; sIndex++) {
          retVKGAccess[selectedVKGs[sIndex]] = accessString
        }
      }
      return retVKGAccess
    }
  }

  // eslint-disable-next-line valid-jsdoc
  /**
   * This function is meant to reduce individual user permissions if their access
   * exceeds the vkgAccess that has just been set by the organization.
   *
   * In order to grant VKG Access, the user must go and grant that access user by user.
   */
  async function updateAllUsersWithVKGAccess(vkgAccess, currentMessage) {
    const promises = []
    const userIds = Object.keys(props.subOrganizationDetails?.userRoster || {})
    const minimumPerm = (vkgAccess?.vkgs || '').replace('create', '')
    for (let uIndex = 0; uIndex < userIds.length; uIndex++) {
      const userId = userIds[uIndex]
      const permissionsCopy = structuredClone(props.subOrganizationDetails?.userRoster?.[userId]?.permissions)
      permissionsCopy['readVKG'] = subOrgCanVKGRead && permissionsCopy['readVKG']
      permissionsCopy['writeVKG'] = subOrgCanVKGWrite && permissionsCopy['writeVKG']
      const usersVKGAccess = props.subOrganizationDetails?.userRoster?.[userId]?.vkgAccess || {}
      let newVKGAccess = structuredClone(usersVKGAccess)
      const vkgKeys = Object.keys(usersVKGAccess || {}).filter((key) => key !== 'vkgs')
      let hasUserAccessChanged = props.subOrganizationDetails?.userRoster?.[userId]?.permissions?.['readVKG'] !== permissionsCopy?.['readVKG'] ||
          props.subOrganizationDetails?.userRoster?.[userId]?.permissions?.['writeVKG'] !== permissionsCopy?.['writeVKG']
      let isFullUserUpdate = false
      if (usersVKGAccess?.vkgs && (usersVKGAccess?.vkgs !== vkgAccess?.vkgs) &&
      (usersVKGAccess?.vkgs?.length >= (vkgAccess?.vkgs || '')?.length ||
      (vkgAccess?.vkgs === 'create' && usersVKGAccess?.vkgs === 'read'))) {
        if (vkgAccess?.vkgs) {
          if (usersVKGAccess?.vkgs === 'create' || (vkgAccess?.vkgs === 'create' && usersVKGAccess?.vkgs === 'read')) {
            delete newVKGAccess.vkgs
          } else if (vkgAccess?.vkgs === 'create' || !vkgAccess?.vkgs) {
            delete newVKGAccess.vkgs
            if (usersVKGAccess?.vkgs?.includes('write')) { // if the user had full access, give them the access being set for the org
              newVKGAccess = vkgAccess
              isFullUserUpdate = true
            }
          } else {
            newVKGAccess.vkgs = vkgAccess?.vkgs
          }
        } else {
          delete newVKGAccess.vkgs
          if (usersVKGAccess?.vkgs?.includes('write')) { // if the user had full access, give them the access being set for the org
            newVKGAccess = vkgAccess
            isFullUserUpdate = true
          }
        }
        hasUserAccessChanged = true
      }
      if (!isFullUserUpdate) {
        for (let vkIndex = 0; vkIndex < vkgKeys.length; vkIndex++) {
          if (usersVKGAccess?.[vkgKeys[vkIndex]] &&
            ((usersVKGAccess?.[vkgKeys[vkIndex]] !== (vkgAccess?.[vkgKeys[vkIndex]] || minimumPerm)) &&
            (usersVKGAccess?.[vkgKeys[vkIndex]]?.length > (vkgAccess?.[vkgKeys[vkIndex]] || minimumPerm)?.length))
          ) {
            if (vkgAccess?.[vkgKeys[vkIndex]] || minimumPerm.length > 0) {
              newVKGAccess[vkgKeys[vkIndex]] = vkgAccess?.[vkgKeys[vkIndex]] || minimumPerm
            } else {
              delete newVKGAccess[vkgKeys[vkIndex]]
            }
            hasUserAccessChanged = true
          }
        }
      }
      if (hasUserAccessChanged) {
        promises.push(new Promise(async (resolve) => {
          const resp = await changeUserPermissions(
              permissionsCopy,
              userId,
              props.subOrganizationDetails?.userRoster?.[userId]?.role,
              newVKGAccess,
          ).then((resp) => {
            return true
          })
              .catch((err) => {
                return false
              })
          resolve(resp)
        }))
      }
    }
    if (promises.length > 0) {
      props.storePayload({
        isLoading: true,
      })
      const successes = await Promise.all(promises)
      const countSuccess = successes.reduce((prev, isSuccess) => prev + (isSuccess ? 1: 0), 0)
      const message = `${currentMessage || ''}\n\n${countSuccess}/${successes.length} User VKG Access Updates were successful.`
      props.storePayload({
        isLoading: false,
        userMessage: message,
        notificationType: 1,
        notificationIcon: (successes.length === countSuccess) ? 'check': 'warning',
        isNotificationVisible: true,
      })
    }
  }

  if (!hasSubOrgAccess()) {
    return <Navigate to='/' />
  }
  return (
    <div className='sub-org-view show-scrollbar'>
      <PermissionSideTab
        email={permissionsEmail}
        headerText={(permissionsID === inviteEmail && inviteEmail.length > 0) ? 'Invite User': 'User Permissions'}
        id={permissionsID}
        permissionUser={permissionsUser}
        isVisible={permissionsModalShowing}
        onClose={() => {
          setPermissionsModalShowing(false)
          setPermissionsUser(null)
        }}
        onSave={savePermissions}
        alternativeVKGList={(hasFullVKGAccess && props.vkgDomain === subOrgVkgDomain) ? Object.keys(props.graphObj || {}) : Object.keys(props.subOrganizationDetails?.vkgAccess || {})}
        shouldShowVKG={props.subOrganizationDetails?.vkgDomain}
        canSetVKGFullAccess={['read', 'write', 'create'].every((perm) => props.subOrganizationDetails?.['vkgAccess']?.vkgs?.includes(perm))}
        isVKGReadPossible={Object.keys(props.subOrganizationDetails?.['vkgAccess'] || {}).some((vkgId) => props.subOrganizationDetails?.['vkgAccess']?.[vkgId]?.includes('read'))}
        isVKGWritePossible={Object.keys(props.subOrganizationDetails?.['vkgAccess'] || {}).some((vkgId) => props.subOrganizationDetails?.['vkgAccess']?.[vkgId]?.includes('write'))}
      />
      {props.subOrganizationDetails &&
        <>
          <div className='sub-org-header-row'>
            <div className='sub-org-header-half'>
              <div className='sub-org-header-name'>
                {props.subOrganizationDetails.orgName}
              </div>
              <div className='sub-org-header-id'>
                {subOrgId}
              </div>
            </div>
            <div className='sub-org-header-half'>
              <div className='sub-org-header-parent-label'>
              Parent
              </div>
              <div className='sub-org-header-parent'>
                <span>{`${props.subOrganizationDetails?.parentOrgName} - ${props.subOrganizationDetails?.parentOrgId}`}</span>
              </div>
            </div>
            <Button
              theme={props.theme}
              className={'sub-org-billing-button'}
              text='Billing History'
              icon={<Icon icon='download-outline' />}
              iconPosition='right'
              iconJustify='edge'
              download={true}
              onClick={getBillingHistory}
            />
          </div>
        </>
      }{props.subOrganizationDetails &&
        <div className='sub-org-row'>
          <div className='sub-org-row-title'>
            Credentials
          </div>
          <div
            className='sub-org-row-body sub-org-row-spacing sub-org-split-row'
          >
            <Card title='Org Id' theme={props.theme} className='background-salem'>
              <p className='card-title text-bright-green'>
                {subOrgId}
              </p>
              <div data-test-id='orgId-container' className='card-copy-section gap-group gap-group-columns gap-group-columns-auto justify-start'>
                <div
                  className='flex'
                >
                </div>
                <div className='flex cursor-pointer' onClick={onClickCopyOrgId}>
                  <div
                    className={`toggle-container ${isOrgIdCopied ? 'toggled' : ''}`}
                  >
                    <Icon icon='checkmark-outline' />
                    <Icon icon='copy-outline' />
                  </div>
                  <span>{isOrgIdCopied ? 'Copied' : 'Copy to Clipboard'}</span>
                </div>
              </div>
            </Card>
            <Card title='Auth Key' theme={props.theme} className='background-salem'>
              <p className='card-title text-bright-green'>
                {isSubOrgAuthKeyShowing ? (props.subOrgAuthKey || 'Loading...') : hiddenString}
              </p>
              <div data-test-id='authKey-container' className='card-copy-section gap-group gap-group-columns gap-group-columns-auto justify-start'>
                <div
                  className='flex cursor-pointer'
                  onClick={showAuthKey}
                >
                  <div
                    className={`toggle-container ${
                    !isSubOrgAuthKeyShowing ? 'toggled' : ''
                    }`}
                  >
                    <Icon icon='eye-outline' />
                    <Icon icon='eye-off-2-outline' />
                  </div>
                  <span>{isSubOrgAuthKeyShowing ? 'Hide' : 'Show'}</span>
                </div>
                {isSubOrgAuthKeyShowing && props.subOrgAuthKey &&
                <div className='flex cursor-pointer' onClick={onClickCopyAuthKey}>
                  <div
                    className={`toggle-container ${isAuthKeyCopied? 'toggled' : ''}`}
                  >
                    <Icon icon='checkmark-outline' />
                    <Icon icon='copy-outline' />
                  </div>
                  <span>{isAuthKeyCopied ? 'Copied' : 'Copy to Clipboard'}</span>
                </div>
                }
              </div>
            </Card>
          </div>
        </div>
      }
      {props.subOrganizationDetails &&
        <div className='sub-org-row'>
          <div className='sub-org-row-title'>
            Invite Users
          </div>
          <div className='sub-org-row-body'>
            <Card
              theme={props.theme}
            >
              <form onSubmit={onClickSendInvite}>
                <Input
                  name='inviteeEmail'
                  onChange={onChangeForm}
                  value={inviteEmail}
                  theme={props.theme}
                  label='Email'
                  type='email'
                  iconLeft={<Icon icon='person-outline' />}
                  placeholder="Type User's Email"
                  required
                />
                <Button
                  buttonType='submit'
                  text='Next'
                  theme={props.theme}
                  icon={<Icon icon='arrow-forward-outline' />}
                  iconPosition='right'
                  iconJustify='edge'
                  disabled={!isBusinessEmail(inviteEmail)}
                />
              </form>
            </Card>
          </div>
        </div>
      }
      {props.subOrganizationDetails?.userRoster &&
        <div className='sub-org-row'>
          <div className='sub-org-row-title'>
            User Roster
          </div>
          <div className='sub-org-row-body'>
            <Table
              showTotal={(subOrgUserEntries && subOrgUserEntries.length) ? true : false}
              columnTitles={['User', 'Date Created', 'Role']}
              tableId={'sub-org-user-table'}
              className={'org-table'}
              entries={subOrgUserEntries === null ?
            [<TableEntry
              key={'loading-row'}
              content={['Sub Organization Users Loading']}
            />] :
            subOrgUserEntries.length ?
              subOrgUserEntries :
              [<TableEntry
                key={'no-sub-row'}
                content={['No Sub Organizations Users']}
              />]
              }
              theme={props.theme}
            />
          </div>
        </div>
      }
      {props.subOrganizationDetails &&
        <div className='sub-org-row'>
          <div className='sub-org-row-title'>
            Model Usage
          </div>
          {(subOrgModelMetrics && Object.keys(subOrgModelMetrics || {}).length) ?
            <Table
              columnTitles={['Model', 'Model Id', 'Successful Requests', 'Total Pages', 'Total Questions', 'Last Used']}
              className={'sub-org-row-body sub-org-row-body-margin-top org-table'}
              showTotal={false}
              theme={props.theme}
              entries={Object.keys(subOrgModelMetrics || {})
                  .filter((modelId) => {
                    return (props.modelInformation?.[modelId] || modelId.toLowerCase().includes('ocr'))
                  })
                  .sort((aModelId, bModelId) => {
                    return subOrgModelMetrics?.[bModelId]?.['total_pages'] - subOrgModelMetrics?.[aModelId]?.['total_pages']
                  })
                  .map((modelId, mIndex) => {
                    return (
                      <TableEntry
                        key={`model-${modelId}-${mIndex}-${Date.now()}`}
                        content={[
                          props.modelInformation?.[modelId]?.public?.metadata?.name || 'N/A',
                          modelId,
                          subOrgModelMetrics[modelId]['successful_requests'],
                          subOrgModelMetrics[modelId]['total_pages'],
                          subOrgModelMetrics[modelId]['total_questions'],
                          (subOrgModelMetrics[modelId]['last_used'] || 'N/A'),
                        ]}
                      />
                    )
                  })}
            /> :
            <div className='sub-org-row-body'>No Model Access</div>
          }
        </div>
      }
      {props.subOrganizationDetails &&
        <div className='sub-org-row'>
          <ModularMetrics
            alternativeModelOptions={subOrgModelAccess}
            metricOrgId={subOrgId}
            metricAuthKey={props.subOrgAuthKey}
          />
        </div>
      }
      {props.subOrganizationDetails &&
        <div className='sub-org-row'>
          <div className='sub-org-row-title'>
            {/* Model Access and Pricing */}
            Model Access
            {!canChangeSubOrg(props.orgDetails) && <span className='view-only'>{' View Only'}</span>}
            {hasModelAccessOrPricingChanged(subOrgModelAccess, subOrgModelPricing, props.subOrganizationDetails) && <>
              <span className='unsaved-text'>{'   Unsaved Changes   '}</span>
              {/* <span
                onClick={() => initializeModelControlState(true)}
                className='reset-text'
              >Reset Changes</span> */}
            </>
            }
          </div>
          <Card
            theme={props.theme}
            className='sub-org-row-body sub-org-row-spacing'
          >
            <Select
              value={props.modelInformation ?
                subOrgModelAccess
                    .filter((modelId) => props.modelInformation?.[modelId]?.public && !['genericFormApi2', 'invoices', 'receipts'].includes(modelId))
                    .map((modelId) => `${modelId} - ${props.modelInformation?.[modelId]?.public?.metadata?.name}`):
                []}
              label='Model Access'
              theme={props.theme}
              isSelectedYellow={true}
              options={props.modelAccess ? props.modelAccess.
                  filter((modelId) => props.modelInformation?.[modelId]?.public && !['genericFormApi2', 'invoices', 'receipts'].includes(modelId)).
                  map((modelId) => `${modelId} - ${props.modelInformation?.[modelId]?.public?.metadata?.name}`) :
                []}
              isMultiselect={true}
              onClickOption={(option) => {
                if (canChangeSubOrg(props.orgDetails)) {
                  if (subOrgModelAccess.includes(option.split(' - ')[0])) {
                    setSubOrgModelAccess(JSON.parse(JSON.stringify(subOrgModelAccess.filter((val) => val !== option.split(' - ')[0]))))
                  } else {
                    setSubOrgModelAccess([...subOrgModelAccess, option.split(' - ')[0]])
                  }
                }
              }}
            />
            {/* {subOrgModelAccess.map((modelId) => {
              return (
                <Fragment key={`${modelId}-price`}>
                  <Switch
                    key={`${modelId}-switch`}
                    label={`Set ${props.modelInformation[modelId]?.public?.metadata?.name} Pricing`}
                    checked={subOrgModelPricing?.[modelId]?.isSetting}
                    value={subOrgModelPricing?.[modelId]?.isSetting}
                    onChange={(e) => {
                      if (canChangeSubOrg(props.orgDetails)) {
                        if (!subOrgModelPricing?.[modelId]) {
                          subOrgModelPricing[modelId] = {isSetting: true}
                        } else if (!subOrgModelPricing?.[modelId]?.isSetting) {
                          subOrgModelPricing[modelId].isSetting = true
                        } else {
                          subOrgModelPricing[modelId].isSetting = false
                        }
                        setSubOrgModelPricing(JSON.parse(JSON.stringify(subOrgModelPricing)))
                      }
                    }}
                    theme={props.theme}
                    id={`${modelId}-switch`}
                  />
                  {subOrgModelPricing?.[modelId]?.isSetting &&
                  <>
                    <Input
                      label='Price Per Page'
                      placeholder='Enter the price per page...'
                      theme={props.theme}
                      type={1}
                      value={`${subOrgModelPricing?.[modelId]?.perPage || ''}`}
                      onChange={(e) => {
                        if (canChangeSubOrg(props.orgDetails)) {
                          subOrgModelPricing[modelId].perPage = e.target.value
                          setSubOrgModelPricing(JSON.parse(JSON.stringify(subOrgModelPricing)))
                        }
                      }}
                      min={0}
                      className={(!subOrgModelPricing[modelId].perPage || parseFloat(subOrgModelPricing[modelId].perPage) > 0) ? '': 'input-error'}
                      style={{marginBottom: '0px', boxSizing: 'border-box', paddingLeft: '16px'}}
                      key={`${modelId}-price-page`}
                    />
                    <Input
                      label='Price Per Question'
                      placeholder='Enter the price per question...'
                      theme={props.theme}
                      type={1}
                      value={`${subOrgModelPricing?.[modelId]?.perQuestion || ''}`}
                      onChange={(e) => {
                        if (canChangeSubOrg(props.orgDetails)) {
                          subOrgModelPricing[modelId].perQuestion = e.target.value
                          setSubOrgModelPricing(JSON.parse(JSON.stringify(subOrgModelPricing)))
                        }
                      }}
                      min={0}
                      className={(!subOrgModelPricing[modelId].perQuestion || parseFloat(subOrgModelPricing[modelId].perQuestion) > 0) ? '': 'input-error'}
                      style={{marginBottom: '0px', boxSizing: 'border-box', paddingLeft: '16px'}}
                      key={`${modelId}-price-question`}
                    />
                    <Input
                      label='Price Per Request'
                      placeholder='Enter the price per request...'
                      theme={props.theme}
                      type={1}
                      value={`${subOrgModelPricing?.[modelId]?.perRequest || ''}`}
                      onChange={(e) => {
                        if (canChangeSubOrg(props.orgDetails)) {
                          subOrgModelPricing[modelId].perRequest = e.target.value
                          setSubOrgModelPricing(JSON.parse(JSON.stringify(subOrgModelPricing)))
                        }
                      }}
                      min={0}
                      className={(!subOrgModelPricing[modelId].perRequest || parseFloat(subOrgModelPricing[modelId].perRequest) > 0) ? '': 'input-error'}
                      style={{marginBottom: '0px', boxSizing: 'border-box', paddingLeft: '16px'}}
                      key={`${modelId}-price-request`}
                    />
                  </>
                  }
                </Fragment>
              )
            })} */}
            {hasModelAccessOrPricingChanged(subOrgModelAccess, subOrgModelPricing, props.subOrganizationDetails) && canChangeSubOrg(props.orgDetails) &&
              <>
                <Button
                  style={{marginBottom: '0px'}}
                  text={'Save Changes'}
                  theme={props.theme}
                  onClick={async () => {
                    props.storePayload({
                      // userMessage: 'Updating model access and pricing...',
                      userMessage: 'Updating model access...',
                      notificationType: 2,
                      notificationIcon: 'check',
                    })
                    const modelAdd = subOrgModelAccess.filter((modelId) => !props.subOrganizationDetails?.models?.[modelId]?.is_active)
                    const modelDel = Object.keys(props.subOrganizationDetails?.models || {}).filter((modelId) => (!subOrgModelAccess.includes(modelId) && props.subOrganizationDetails?.models?.[modelId]?.is_active))
                    const modelPricing = Object.assign({}, ...Object.keys(subOrgModelPricing || {})
                        .filter((modelId) => subOrgModelPricing[modelId]['isSetting'] && subOrgModelAccess.includes(modelId))
                        .map((modelId) => {
                          const interObj = {}
                          interObj['perPage'] = parseFloat(subOrgModelPricing[modelId]['perPage']) || null
                          interObj['perQuestion'] = parseFloat(subOrgModelPricing[modelId]['perQuestion']) || null
                          interObj['perRequest'] = parseFloat(subOrgModelPricing[modelId]['perRequest']) || null
                          const returnObj = {}
                          returnObj[modelId] = interObj
                          return returnObj
                        }))
                    await props.setSubOrgModelAccessPricing(props.orgId, props.authKey, props.user.uid,
                        subOrgId, modelAdd, modelDel, modelPricing)
                    props.getSubOrganizationDetails(props.orgId, props.authKey, props.user.uid, subOrgId, true)
                  }}
                />
                <Button
                  style={{marginBottom: '0px'}}
                  text={'Revert Changes'}
                  theme={props.theme}
                  type={10}
                  onClick={() => {
                    initializeModelControlState(true)
                  }}
                />
              </>
            }
          </Card>
        </div>
      }
      {props.subOrganizationDetails && props.modelInformation &&
        <div className='sub-org-row'>
          <LimitInputs
            canChangeLimits={canChangeSubOrg(props.orgDetails)}
            subOrgId={subOrgId}
            specificModelAccess={Object.keys(props.subOrganizationDetails?.models || {})
                .filter((modelId) => {
                  return (props.subOrganizationDetails?.models?.[modelId]?.is_active && props.modelInformation?.[modelId])
                })}
          />
        </div>
      }
      {props.subOrganizationDetails && props.vkgDomain?.length > 0 && process.env.REACT_APP_ENVIRONMENT_NAME !== 'Testing' &&
        <div className='sub-org-row'>
          <div className='sub-org-row-title'>
          VKG Control
            {!canChangeSubOrg(props.orgDetails) && <span className='view-only'>{' View Only'}</span>}
            {hasSubOrgVKGInfoChanged(subOrgVkgDomain, subOrgLicenseKey, generateVKGAccess(selectedVKGs, subOrgCanVKGRead, subOrgCanVKGWrite, hasFullVKGAccess)) && <>
              <span className='unsaved-text'>{'   Unsaved Changes   '}</span>
              <span
                onClick={() => initializeVKGValues()}
                className='reset-text'
              >Reset Changes</span>
            </>}
          </div>
          <Card
            theme={props.theme}
            className={`sub-org-row-body sub-org-row-spacing ${!canChangeSubOrg(props.orgDetails) ? 'pointer-events-none': ''}`}
          >
            {(subOrgVkgDomain !== props.vkgDomain || subOrgLicenseKey !== props.licenseKey) &&
            <Button
              text={`Grant ${props.subOrganizationDetails?.orgName} access to your VKG instance`}
              onClick={() => {
                setSubOrgLicenseKey(props.licenseKey)
                setSubOrgVkgDomain(props.vkgDomain)
              }}
              theme={props.theme}
            />}
            <Input
              label={'VKG Domain'}
              onChange={(e) => {
                setSubOrgVkgDomain(e.target.value)
              }}
              value={subOrgVkgDomain}
              placeholder={'Enter the VKG Domain...'}
              info={'VKG Domains should be always be the base url. Ex: https://***.lazarusforms.com (should not end in a slash)'}
              theme={props.theme}
            />
            <Input
              label={'VKG License Key'}
              onChange={(e) => {
                setSubOrgLicenseKey(e.target.value)
              }}
              value={subOrgLicenseKey}
              placeholder={'Enter the License Key...'}
              theme={props.theme}
            />
            <div className='sub-org-vkg-perms'>
              <div
                className='permission-sub-header'
              >
                Permission
              </div>
              <div
                className='permission-collapse-body-row permission-side-section'
              >
                <div
                  className={`permission-collapse-body-row-text-section ${(['read', 'write'].every((perm) => props.userData?.vkgAccess?.vkgs?.includes(perm)))}`}
                >
                  <div className='permission-collapse-body-row-text-main'>
                  Read VKGs
                  </div>
                  <div className='permission-collapse-body-row-text-secondary'>
                  Get graphs and nodes, search VKG, use RikYChat, and export VKGs.
                  </div>
                </div>
                <div
                  className='permission-collapse-body-row-switch'
                  onClick={() => {
                    setSubOrgCanVKGRead(!subOrgCanVKGRead)
                  }}
                >
                  <Icon
                    key={subOrgCanVKGRead ? 'radio-button-on-full': 'radio-button-off-full'}
                    icon={subOrgCanVKGRead ? 'radio-button-on': 'radio-button-off'}
                    className={subOrgCanVKGRead ? 'permission-active-icon': ''}
                  />
                </div>
              </div>
              <div
                className='permission-collapse-body-row permission-side-section'
              >
                <div
                  className={`permission-collapse-body-row-text-section ${(['read', 'write'].every((perm) => props.userData?.vkgAccess?.vkgs?.includes(perm)))}`}
                >
                  <div className='permission-collapse-body-row-text-main'>
                  Write VKGs
                  </div>
                  <div className='permission-collapse-body-row-text-secondary'>
                  Create VKGs, add, edit, and delete nodes, delete VKGs, compute TSNE, and duplicate VKGs.
                  </div>
                </div>
                <div
                  className='permission-collapse-body-row-switch'
                  onClick={() => {
                    if (!subOrgCanVKGWrite) {
                      setSubOrgCanVKGRead(true)
                    }
                    setSubOrgCanVKGWrite(!subOrgCanVKGWrite)
                  }}
                >
                  <Icon
                    key={subOrgCanVKGWrite ? 'radio-button-on-full': 'radio-button-off-full'}
                    icon={subOrgCanVKGWrite ? 'radio-button-on': 'radio-button-off'}
                    className={subOrgCanVKGWrite ? 'permission-active-icon': ''}
                  />
                </div>
              </div>
              <div className='permission-sub-header'>
                Access
              </div>
              <div
                className='permission-collapse-body-row permission-side-section'
              >
                <div
                  className={`permission-collapse-body-row-text-section ${(['read', 'write'].every((perm) => props.userData?.vkgAccess?.vkgs?.includes(perm)))}`}
                >
                  <div className='permission-collapse-body-row-text-main'>
                    Full VKG Server Access
                  </div>
                  <div className='permission-collapse-body-row-text-secondary'>
                    Access to all VKGs within an organization instance.
                  </div>
                </div>
                <div
                  className='permission-collapse-body-row-switch'
                  onClick={() => {
                    setHasFullVKGAccess(true)
                  }}
                >
                  <Icon
                    key={hasFullVKGAccess ? 'radio-button-on-full': 'radio-button-off-full'}
                    icon={hasFullVKGAccess ? 'radio-button-on': 'radio-button-off'}
                    className={hasFullVKGAccess ? 'permission-active-icon': ''}
                  />
                </div>
              </div>
              <div
                className={`permission-collapse-body-row permission-side-section ${(props.vkgDomain !== subOrgVkgDomain) ? 'disabled-graph-select': ''}`}
              >
                <div
                  className='permission-collapse-body-row-text-section'
                >
                  <div className='permission-collapse-body-row-text-main'>
                    Graph-Specific Access Only
                  </div>
                  <div className='permission-collapse-body-row-text-secondary'>
                    Access to only the VKGs specified
                  </div>
                  {(props.vkgDomain !== subOrgVkgDomain) && <div className='permission-collapse-body-row-text-secondary disabled-notification'>
                    Cannot set graph-specific access for a sub org with a different VKG Domains.
                  </div>}
                </div>
                <div
                  className='permission-collapse-body-row-switch'
                  onClick={() => {
                    if (props.vkgDomain === subOrgVkgDomain) {
                      setHasFullVKGAccess(false)
                    }
                  }}
                >
                  <Icon
                    key={hasFullVKGAccess ? 'radio-button-on': 'radio-button-off'}
                    icon={!hasFullVKGAccess ? 'radio-button-on': 'radio-button-off'}
                    className={!hasFullVKGAccess ? 'permission-active-icon': ''}
                  />
                </div>
              </div>
              {/* VKG Select */}
              {!hasFullVKGAccess && <>
                <div className='permission-side-section permission-select-vkgs-header'>
              Select VKGs
                </div>
                <Input
                  theme={props.theme}
                  value={vkgSearchString}
                  onChange={(e) => {
                    setVKGSearchString(e.target.value)
                  }}
                  iconLeft={<Icon icon={'search-outline'} />}
                  placeholder={'Search'}
                  className={'permission-side-section'}
                />
                <div
                  className='permission-select-vkgs-info permission-side-section'
                >
                  <div
                    className='permission-select-vkgs-info-count'
                  >
                    {`${selectedVKGs.length} VKGs selected`}
                  </div>
                  <div
                    className='permission-select-vkgs-info-options'
                  >
                    <span
                      className='option-text'
                      onClick={() => {
                        setSelectedVKGs(Object.keys(props.graphObj || {}))
                      }}
                    >{`Select All  `}</span>
                    <span className='dot-icon'>&bull;</span>
                    <span
                      className='option-text'
                      onClick={() => {
                        setSelectedVKGs([])
                      }}
                    >{`  Clear`}</span>
                  </div>
                </div>
                <div
                  className='permission-vkg-lists show-scrollbar'
                >
                  {Object.keys(props.graphObj || {})
                      .sort()
                      .sort((aId, bId) => {
                        const aValueInter = aId.toLowerCase().indexOf(vkgSearchString.toLowerCase())
                        const bValueInter = bId.toLowerCase().indexOf(vkgSearchString.toLowerCase())
                        const aValue = aValueInter > -1 ? aValueInter : 1000
                        const bValue = bValueInter > -1 ? bValueInter : 1000
                        return aValue - bValue
                      })
                      .map(
                          (vkgId) => {
                            return (
                              <div
                                key={`vkg-select-${vkgId}`}
                                className='permission-side-section permission-vkg-select-row'
                              >
                                <div
                                  className='permission-vkg-select-check'
                                >
                                  <Switch
                                    type='checkbox'
                                    checked={selectedVKGs.includes(vkgId)}
                                    onChange={(e) => {
                                      if (selectedVKGs.includes(vkgId)) {
                                        setSelectedVKGs([...selectedVKGs.filter((graphId) => graphId !== vkgId)])
                                      } else {
                                        setSelectedVKGs([...selectedVKGs, vkgId])
                                      }
                                    }}
                                    theme={props.theme}
                                  />
                                </div>
                                <div
                                  className='permission-vkg-select-text'
                                >
                                  <div
                                    className='permission-vkg-select-name'
                                  >
                                    {vkgId}
                                  </div>
                                  <div
                                    className='permission-vkg-select-nodes'
                                  >
                                    {`${props.graphObj[vkgId]?.nodes} nodes`}
                                  </div>
                                </div>
                              </div>
                            )
                          })
                  }
                </div>
              </>
              }
            </div>
            {hasSubOrgVKGInfoChanged(subOrgVkgDomain, subOrgLicenseKey, generateVKGAccess(selectedVKGs, subOrgCanVKGRead, subOrgCanVKGWrite, hasFullVKGAccess)) && <>
              <div className='access-save-info'>
                {`Removal of VKG Access on a sub organization will apply to all of its users.`}
              </div>
              <Button
                text={`Save VKG Changes`}
                onClick={async () => {
                  const vkgAccess = generateVKGAccess(selectedVKGs, subOrgCanVKGRead, subOrgCanVKGWrite, hasFullVKGAccess)
                  const body = {
                    licenseKey: subOrgLicenseKey,
                    vkgDomain: subOrgVkgDomain,
                    vkgAccess: vkgAccess,
                  }
                  const [success, message] = await props.setSubOrgVKGValues(props.orgId, props.authKey, props.user.uid, subOrgId, body)
                  if (success) {
                    await updateAllUsersWithVKGAccess(vkgAccess, message)
                  }
                  props.getSubOrganizationDetails(props.orgId, props.authKey, props.user.uid, subOrgId, true)
                }}
                theme={props.theme}
              />
            </>}
          </Card>
        </div>

      }
      {props.subOrganizationDetails &&
        <div className='sub-org-row'>
          <div className='sub-org-row-title'>
            Sub Org Control
            {!canChangeSubOrg(props.orgDetails) && <span className='view-only'>{' View Only'}</span>}
            {subOrgCanCreateSubOrg !== props.subOrganizationDetails?.orgPermissions?.canCreateSubOrg && <>
              <span className='unsaved-text'>{'   Unsaved Changes   '}</span>
              <span
                onClick={() => initializeCanCreateSubOrg(true)}
                className='reset-text'
              >Reset Changes</span>
            </>}
          </div>
          <Card
            theme={props.theme}
            className={`sub-org-row-body sub-org-row-spacing ${!canChangeSubOrg(props.orgDetails) ? 'pointer-events-none': ''}`}
          >
            Granting this access will allow this organization to create more sub organizations and control the access/pricing/.etc of those organizations
            <Switch
              key={'permission-switch'}
              label={`Can Create Sub Orgs`}
              checked={subOrgCanCreateSubOrg}
              onChange={(e) => {
                if (canChangeSubOrg(props.orgDetails)) {
                  setSubOrgCanCreateSubOrg(e.target.checked)
                }
              }}
              theme={props.theme}
              id={`permission-switch`}
            />
            {subOrgCanCreateSubOrg !== props.subOrganizationDetails?.orgPermissions?.canCreateSubOrg &&
              canChangeSubOrg(props.orgDetails) &&
              <Button
                style={{marginBottom: '0px'}}
                text={'Save Changes'}
                theme={props.theme}
                onClick={async () => {
                  props.storePayload({
                    userMessage: 'Updating permissions...',
                    notificationType: 1,
                    notificationIcon: 'check',
                  })
                  // API CALL TO UPDATE
                  await props.updateSubOrgCreatePermission(props.orgId, props.authKey, props.user.uid,
                      subOrgId, subOrgCanCreateSubOrg)
                  props.getSubOrganizationDetails(props.orgId, props.authKey, props.user.uid, subOrgId, true)
                }}
              />
            }
          </Card>
        </div>
      }{props.subOrganizationDetails &&
        props.subOrgAuthKey &&
        <div className='sub-org-row'>
          <div className='sub-org-row-title'>
            Reset Sub Organization Auth Key
          </div>
          <Card
            theme={props.theme}
            className='sub-org-row-body sub-org-row-spacing reset-auth-key-body'
          >
            Resetting this Sub Organizations Auth Key will immediately affect the processes of this organization
            <Switch
              key={'auth-key-switch'}
              label={`I confirm that I want to reset ${props.subOrganizationDetails.orgName}'s Auth Key`}
              checked={confirmResetAuthKey}
              onChange={(e) => {
                setConfirmResetAuthKey(e.target.checked)
              }}
              theme={props.theme}
              type='checkbox'
              id={`auth-key-switch`}
            />
            {confirmResetAuthKey &&
              <Button
                style={{marginBottom: '0px'}}
                text={'Save Changes'}
                theme={props.theme}
                onClick={async () => {
                  if (confirm(`Are you sure that you want to reset ${props.subOrganizationDetails.orgName}'s Auth Key?`)) {
                    props.storePayload({
                      userMessage: 'Resetting Auth Key...',
                      notificationType: 1,
                      notificationIcon: 'check',
                    })
                    await props.resetSubOrganizationAuthKey(props.orgId, props.authKey, props.user.uid,
                        subOrgId)
                    setConfirmResetAuthKey(false)
                    setIsSubOrgAuthKeyShowing(false)
                  } else {
                    setConfirmResetAuthKey(false)
                  }
                }}
              />
            }
          </Card>
        </div>
      }
      <div className='sub-org-bottom-padding'>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  usersObj: state.userReducer.usersObj,
  userMessage: state.userReducer.userMessage,
  users: state.userReducer.users,
  userData: state.userReducer.userData,
  user: state.userReducer.user,
  orgDetails: state.userReducer.orgDetails,
  theme: state.userReducer.theme,
  orgId: state.userReducer.orgId,
  subOrganizationMetrics: state.userReducer.subOrganizationMetrics,
  subOrganizationDetails: state.userReducer.subOrganizationDetails,
  modelAccess: state.userReducer.modelAccess,
  modelInformation: state.userReducer.modelInformation,
  authKey: state.userReducer.authKey,
  vkgDomain: state.userReducer.vkgDomain,
  licenseKey: state.userReducer.licenseKey,
  subOrgAuthKey: state.userReducer.subOrgAuthKey,
  subOrganizationLimits: state.userReducer.subOrganizationLimits,
  graphObj: state.userReducer.graphObj,
})

export default connect(mapStateToProps, {
  getUsers,
  storePayload,
  // getBilling,
  getSubOrganizationDetails,
  setSubOrgModelAccessPricing,
  setSubOrgLimits,
  getSubOrganizationAuthKey,
  resetSubOrganizationAuthKey,
  getSubOrgBillingHistory,
  updateSubOrgCreatePermission,
  getSubOrganizationLimits,
  setSubOrgVKGValues,
})(SubOrgView)
