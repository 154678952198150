/* eslint-disable max-len */
import React, {useState, useRef, useEffect} from 'react'
import {Navigate} from 'react-router-dom'
import {connect} from 'react-redux'
import {logAnalyticsEvent} from '../actions/logAnalyticsEvent'
import Code from './Code.jsx'
import {Card, Button, Icon, Input} from '@lazarusai/forms-ui-components'
import Helpers from '../Helpers.js'
import formsMethods from './CodeSnippets/FormsMethods'
import codeSnippetsFunction from './CodeSnippets'
import '../styles/Quickstart.css'
import ModelInfo from '../ModelInfo.js'
import codingLanguageList from '../CodingLanguages'
import {storePayload} from '../actions/storePayload'

function Quickstart(props) {
  const [selectedLanguage, setSelectedLanguage] = useState(null)
  const [selectedModel, setSelectedModel] = useState('custom')
  const [copyData, setCopyData] = useState({})
  const ourFormLink =
    'https://storage.googleapis.com/lazarus-forms-examples/Sample%20Form.pdf'
  // eslint-disable-next-line no-unused-vars
  const [copiedText, _setCopiedText] = useState('')
  const copiedTextRef = useRef()
  function setCopiedText(data) {
    copiedTextRef.current = data
    _setCopiedText(data)
  }
  const [orgId, setOrgID] = useState(`'****************'`)
  const [authKey, setAuthKey] = useState(`'****************'`)
  const [code, setCode] = useState('')
  const [hidingCredentials, setHidingCredentials] = useState(true)

  const toggleCredentials = () => {
    if (hidingCredentials) {
      setOrgID(`"${props.orgId}"`)
      setAuthKey(`"${props.authKey}"`)
    } else {
      setOrgID(`"****************"`)
      setAuthKey(`"****************"`)
    }

    setHidingCredentials(!hidingCredentials)
  }

  const [selectedMethodIndex, setSelectedMethodIndex] = useState(1)

  const noRikAIAccess =
    selectedModel === 'rikAI' &&
    !(
      props.orgDetails &&
      props.orgDetails['permissions'] &&
      props.orgDetails['permissions']['canUseRikai']
    )

  function getCodeSnippet(hideCredentials) {
    const codeSnippets = codeSnippetsFunction(orgId, authKey)
    const output = codeSnippets?.[selectedModel]?.[selectedLanguage]?.[formsMethods[selectedMethodIndex]]
    return output
  }

  useEffect(() => {
    setCode(getCodeSnippet(hidingCredentials))
  }, [hidingCredentials, selectedLanguage, selectedMethodIndex, selectedModel])

  useEffect(() => {
    if (props.user) {
      const codeBlockElement = document.getElementById('code-snippet')
      if (codeBlockElement) {
        codeBlockElement.addEventListener('copy', (e) => {
          e.preventDefault()
          e.stopImmediatePropagation()
          props.logAnalyticsEvent('copy_code_snippet_drag_and_click', {
            'page': 'quickstart',
            'timestamp': Date.now(),
            'user': window.navigator.userAgent,
            'event_type': 'click',
            'uid': props.user.uid,
            'model': selectedModel,
            'language_selected': selectedLanguage,
            'method': formsMethods[selectedMethodIndex],
          })
        })
      }

      const formLinkElement = document.getElementById('form-link')
      if (formLinkElement) {
        formLinkElement.addEventListener('copy', (e) => {
          e.preventDefault()
          e.stopImmediatePropagation()
          props.logAnalyticsEvent('copy_sample_link_url_drag_and_click', {
            'page': 'quickstart',
            'timestamp': Date.now(),
            'user': window.navigator.userAgent,
            'event_type': 'click',
            'uid': props.user.uid,
            'model': selectedModel,
            'language_selected': selectedLanguage,
            'method': formsMethods[selectedMethodIndex],
          })
        })
      }
    }
  }, [props.user])

  useEffect(() => {
    if (props.orgDetails && !selectedLanguage) {
      const language = props.orgDetails?.defaults?.defaultLanguage || 'python'
      setSelectedLanguage(language.toLowerCase())
    }
  }, [props.orgDetails])

  useEffect(() => {
    const codeBlockElement = document.getElementById('code-snippet')
    if (codeBlockElement) {
      codeBlockElement.addEventListener('copy', (e) => {
        e.preventDefault()
        e.stopImmediatePropagation()
        props.logAnalyticsEvent('copy_code_snippet_drag_and_click', {
          'page': 'quickstart',
          'timestamp': Date.now(),
          'user': window.navigator.userAgent,
          'event_type': 'click',
          'uid': props.user.uid,
          'model': selectedModel,
          'language_selected': selectedLanguage,
          'method': formsMethods[selectedMethodIndex],
        })
      })
    }

    const formLinkElement = document.getElementById('form-link')
    if (formLinkElement) {
      formLinkElement.addEventListener('copy', (e) => {
        e.preventDefault()
        e.stopImmediatePropagation()
        props.logAnalyticsEvent('copy_sample_link_url_drag_and_click', {
          'page': 'quickstart',
          'timestamp': Date.now(),
          'user': window.navigator.userAgent,
          'event_type': 'click',
          'uid': props.user.uid,
          'model': selectedModel,
          'language_selected': selectedLanguage,
          'method': formsMethods[selectedMethodIndex],
        })
      })
    }
  }, [])

  const quickstartPermissions = [
    'viewCredentials',
  ]
  const isQuickstartLoading = !props.usersObj

  const permissions = props.usersObj?.[props.user.uid]?.permissions
  const canAccessPage =
    props.usersObj?.[props.user.uid]?.role === 'admin' || (permissions && quickstartPermissions.every((permission) => {
      return permissions[permission]
    }))

  if (isQuickstartLoading) {
    props.storePayload({
      isLoading: true,
    })
    return <></>
  } else if (!canAccessPage) {
    props.storePayload({
      isLoading: false,
    })
    return <Navigate to='/'/>
  } else {
    props.storePayload({
      isLoading: false,
    })
    return (
      <div id='quickstart-section' className='show-scrollbar'>
        <div className='quickstart-left show-scrollbar'>
          <Card
            className='background-metallic-blue'
            title='Welcome'
            theme={props.theme}
          >
            <div>
              <h5>Get started by following the steps below:</h5>
              <a
                className='text-bright-green'
                href='https://lazarus-ai.atlassian.net/servicedesk/customer/portal/5'
                target='_blank'
                rel='noreferrer'
              >
                Still not clear?&nbsp;Contact us
              </a>
            </div>
          </Card>
          <Card
            title='Quickstart'
            className='background-gray-10'
            theme={props.theme}
          >
            <div className='gap-group'>
              <Card className='background-gray-15 model-desc' theme={props.theme}>
                <div>
                  <h5 className='text-light-blue'>
                    Model:&nbsp;
                    {ModelInfo[selectedModel]['display']}
                  </h5>
                  <p className='text-blue-gray-light text-small'>
                    {ModelInfo[selectedModel]['description']}
                  </p>
                </div>
              </Card>
              <div className='gap-group-columns'>
                <Input
                  name='model'
                  id='model'
                  value={selectedModel}
                  onChange={(e) => {
                    setSelectedModel(e.target.value)
                  }}
                  type={3}
                  theme={props.theme}
                  label='1. Pick a model type'
                >
                  {Object.keys(ModelInfo).map((modelName, i) => {
                    return (
                      <option
                        key={'model-' + i}
                        id={modelName}
                        value={modelName}
                      >
                        {ModelInfo[modelName]?.display}
                      </option>
                    )
                  })}
                </Input>
                <Input
                  name='language'
                  id='language'
                  value={selectedLanguage}
                  onChange={(e) => {
                    setSelectedLanguage(e.target.value)
                  }}
                  type={3}
                  theme={props.theme}
                  label='2. Pick a language'
                >
                  {codingLanguageList.map((language, i) => {
                    const lowerLanguage = language.toLowerCase()
                    return (
                      <option
                        key={'language-' + i}
                        id={lowerLanguage}
                        value={lowerLanguage}
                      >
                        {language}
                      </option>
                    )
                  })}
                </Input>
              </div>
              <Input
                name='method'
                id='method'
                onChange={(e) => {
                  setSelectedMethodIndex(e.target.value)
                }}
                defaultValue={1}
                value={selectedMethodIndex}
                type={3}
                theme={props.theme}
                label='3. Pick a method'
              >
                <option value={1}>Upload a URL via JSON, get back a JSON</option>
                <option value={0}>
                  Upload a file via multipart-form, get back a JSON
                </option>
                <option value={2}>
                  Upload a file via base64, get back a JSON
                </option>
              </Input>
            </div>
          </Card>
          <Card
            title={
              <div className='flex justify-space-between align-center overflow-hidden'>
                <span>Code Sample</span>
                <div
                  className={`toggle-container ${
                    copiedText === code ? 'toggled' : ''
                  }`}
                  onClick={() => {
                    Helpers.copyToClipBoard(getCodeSnippet(false), () => {
                      setCopiedText(code)
                      setCopyData({
                        ...copyData,
                        model: selectedModel,
                        language: selectedLanguage,
                        methodIndex: selectedMethodIndex,
                      })
                      props.logAnalyticsEvent('copy_code_snippet_button', {
                        'page': 'quickstart',
                        'timestamp': Date.now(),
                        'user': window.navigator.userAgent,
                        'event_type': 'click',
                        'uid': props.user.uid,
                        'model': selectedModel,
                        'language_selected': selectedLanguage,
                        'method': formsMethods[selectedMethodIndex],
                      })
                    })
                    setTimeout(() => {
                      setCopiedText()
                    }, 4000)
                  }}
                >
                  <Icon icon='checkmark-outline' />
                  <Icon icon='copy-outline' />
                </div>
              </div>
            }
            theme={props.theme}
          >
            <a
              className='text-align-right text-bright-green'
              onClick={() => toggleCredentials()}
            >
              {hidingCredentials === true ? 'Show' : 'Hide'} Credentials
            </a>
            <div className='code-snippet' id='code-snippet'>
              {noRikAIAccess && selectedModel === 'rikai' ? (
                <div className='no-access-warning'>
                  <p>
                    You do not have access to RikAI. To get access, fill out this
                    form and our team will follow up.
                  </p>
                  <Button
                    className='warning-link'
                    id='code-snippet-code'
                    href='https://calendly.com/lazarus-ariel/lazarus-forms-rikai-demo'
                    target='_blank'
                    rel='noopener noreferrer'
                    text='Request Access'
                    type={9}
                    theme={props.theme}
                  ></Button>
                </div>
              ) : (
                <>
                  <Code
                    language={selectedLanguage}
                    code={
                      code
                    }
                    theme={props.theme}
                  ></Code>
                </>
              )}
            </div>
          </Card>
          <Card theme={props.theme}>
            <div>
              <h4>Don&apos;t have your own form? Use ours.</h4>
              <a href={ourFormLink} id='form-link'>
                <h3 className='text-bright-green overflow-hidden'>
                  <div></div> {/* needed to remove Safari overflow tooltip */}
                  {ourFormLink}
                </h3>
              </a>
            </div>

            <a
              className='copy-div flex align-center'
              onClick={() => {
                Helpers.copyToClipBoard(ourFormLink, () => {
                  setCopiedText(ourFormLink)
                  props.logAnalyticsEvent('copy_sample_link_url_button', {
                    'page': 'quickstart',
                    'timestamp': Date.now(),
                    'user': window.navigator.userAgent,
                    'event_type': 'click',
                    'uid': props.user.uid,
                    'model': selectedModel,
                    'language_selected': selectedLanguage,
                    'method': formsMethods[selectedMethodIndex],
                  })
                })
                setTimeout(() => {
                  setCopiedText()
                }, 4000)
              }}
            >
              <div
                className={`toggle-container ${
                  copiedText === ourFormLink ? 'toggled' : ''
                }`}
                onClick={() => {
                  Helpers.copyToClipBoard(ourFormLink, () => {
                    setCopiedText(ourFormLink)
                    setCopyData({})
                    props.logAnalyticsEvent('copy_sample_link_url_button', {
                      'page': 'quickstart',
                      'timestamp': Date.now(),
                      'user': window.navigator.userAgent,
                      'event_type': 'click',
                      'uid': props.user.uid,
                      'model': selectedModel,
                      'language_selected': selectedLanguage,
                      'method': formsMethods[selectedMethodIndex],
                    })
                  })
                  setTimeout(() => {
                    setCopiedText()
                  }, 4000)
                }}
              >
                <Icon icon='checkmark-outline' />
                <Icon icon='copy-outline' />
              </div>
              {copiedText === ourFormLink ? 'Copied' : 'Copy to clipboard'}
            </a>
          </Card>
        </div>
        <div className='quickstart-right'>
          {/* <a
            href='https://www.lazarusforms.com/'
            target='_blank'
            rel='noreferrer'
          >
            <Button
              icon={<Icon icon='question-mark-outline' />}
              iconJustify='edge'
              theme={props.theme}
              text='FAQ'
            />
          </a> */}
          <a
            href='https://app.lazarusforms.com/docs#lazarus-forms-api'
            target='_blank'
            rel='noreferrer'
          >
            <Button
              icon={<Icon icon='code-outline' />}
              iconJustify='edge'
              theme={props.theme}
              text={'Full Docs'}
              onClick={() => {
                props.logAnalyticsEvent('access_full_docs', {
                  'page': 'quickstart',
                  'timestamp': Date.now(),
                  'user': window.navigator.userAgent,
                  'event_type': 'navigation',
                  'uid': props.user.uid,
                })
              }}
            />
          </a>
          <a
            href='https://lazarus-ai.atlassian.net/servicedesk/customer/portal/5'
            target='_blank'
            rel='noreferrer'
            className='contactUs'
          >
            <Button
              icon={<Icon icon='message-square-outline' />}
              iconJustify='edge'
              theme={props.theme}
              text='Contact Us'
            />
          </a>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  orgId: state.userReducer.orgId,
  user: state.userReducer.user,
  usersObj: state.userReducer.usersObj,
  authKey: state.userReducer.authKey,
  billing: state.userReducer.billing,
  orgDetails: state.userReducer.orgDetails,
  theme: state.userReducer.theme,
  analytics: state.firebaseReducer.analytics,
})

export default connect(mapStateToProps, {logAnalyticsEvent, storePayload})(Quickstart)
