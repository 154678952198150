// Dependencies
import React, {useState, useEffect, useRef, Fragment} from 'react'
import {Route, Routes, BrowserRouter, Navigate} from 'react-router-dom'
import firebase from 'firebase/compat/app'
import 'firebase/compat/analytics'
import {connect} from 'react-redux'
import Helpers from '../Helpers.js'

// Actions
import {createFirebaseApp} from '../actions/createFirebaseApp'
import {initializeAnalytics} from '../actions/initializeAnalytics.js'
import {onSignInSuccess} from '../actions/onSignInSuccess.js'
import {storePayload} from '../actions/storePayload'
import {getCustomerPortal} from '../actions/getCustomerPortal.js'
import {getVersionDetails} from '../actions/getVersionDetails.js'
import {signOut} from '../actions/signOut.js'
import {getSubOrganizationMetrics} from '../actions/getSubOrganizationMetrics.js'

// Components
import Auth from './Auth.jsx'
// import AuthModal from './AuthModal.jsx'
import ForgotPassword from './ForgotPassword.jsx'
import Template from './Template.jsx'
import Dashboard from './Dashboard.jsx'
import Training from './Training.jsx'
import Api from './Api.jsx'
import Quickstart from './Quickstart.jsx'
import Settings from './Settings.jsx'
import FullLoadingScreen from './FullLoadingScreen.jsx'
import AwaitingVerification from './AwaitingVerification.jsx'
import Documentation from './Documentation.jsx'
import Welcome from './Welcome.jsx'
import CookiesModal from './CookiesModal.jsx'
import VKGDashboard from './VKGDashboard.jsx'
import DemoWrapper from './DemoWrapper.jsx'
import Models from './Models.jsx'
import SubOrgDashboard from './SubOrgDashboard.jsx'
import SubOrgView from './SubOrgView.jsx'
import {Notification, Modal, Icon, Switch, Input} from '@lazarusai/forms-ui-components'

// Images
import Background from '../images/Sign_In_BKG.png'

// Styles
import '@lazarusai/forms-ui-components/dist/index.css'
import '@lazarusai/forms-demo/dist/index.css'

import '../styles/App.css'
import AccountInitialization from './AccountInitialization.jsx'
// import Modal from './Modal.jsx'

import {QRCodeSVG} from 'qrcode.react'
import RequestLoadingScreen from './RequestLoadingScreen.jsx'

function App(props) {
  const checkUserVerification = useRef()
  const [timerInterval, setTimerInterval] = useState()
  const [time, setTime] = useState(Date.now())
  const [showBillingModal, setShowBillingModal] = useState(false)
  const [isTermsModalShowing, setIsTermsModalShowing] = useState(false)
  const [isCookiesConsentModalShowing, setIsCookiesConsentModalShowing] = useState(false)
  const [isCookiesConsentModal, setIsCookiesConsentModal] = useState(false)
  const [termsConditionsAgreed, setTermsConditionsAgreed] = useState(false)
  const [privacyPolicyAgreed, setPrivacyPolicyAgreed] = useState(false)
  const [isTermsModal, setIsTermsModal] = useState(false)
  const [MFAOTPInput, setMFAOTPInput] = useState('')
  const [reauthPassword, setReauthPassword] = useState('')
  const [reauthOTPInput, setReauthOTPInput] = useState('')
  const [showSignInPassword, setShowSignInPassword] = useState(false)

  // useEffect(() => {
  //   if (
  //     props.billing &&
  //     ((!props.billing.contract ||
  //     !props.billing.contract['Active']) &&
  //     props.billing.promo['amount'] <= 0)
  //   ) {
  //     setShowBillingModal(true)
  //   }
  // }, [props.billing])

  /* animates notification */
  const onClickNotification = () => {
    props.storePayload({
      isNotificationVisible: false,
    })
    setTimeout(() => {
      props.storePayload({
        userMessage: null,
        notificationType: null,
        notificationIcon: null,
      })
      props.storePayload({
        isNotificationVisible: true,
      })
    }, 300)
  }

  /* Component mount, create firebase app */
  useEffect(() => {
    props.createFirebaseApp()
    if (timerInterval) {
      clearInterval(timerInterval)
    }

    // update time every 10s to check for inactive users
    const newTimerInterval = setInterval(() => {
      setTime(Date.now())
    }, [10000])

    setTimerInterval(newTimerInterval)

    // add click listner to check for inactive users
    window.addEventListener('click', () => {
      props.storePayload({
        timeOfLastAction: Date.now(),
      })
    });

    const isMobileUserAgent = /mobile|android|iphone|ipad|ipod/i.test(navigator.userAgent)
    const isMobileSize = window.innerWidth < 900
    if (isMobileUserAgent && isMobileSize) {
      props.storePayload({
        userMessage: 'For the best experience, please use our dashboard on a desktop.',
        notificationIcon: 'warning',
        notificationType: 3,
      })
    }
  }, [])

  // Log inactive users out after timeLimit
  useEffect(() => {
    const timeLimit = 600000 // 10 min
    const timeSinceLastAction = time - props.timeOfLastAction
    if (timeSinceLastAction >= timeLimit) {
      if (props.isSignedIn) {
        setTimeout(() => {
          props.storePayload({
            userMessage: 'You have been signed out due to inactivity.',
            notificationIcon: 'warning',
            notificationType: 2,
          })
        }, [0])
        props.signOut(props.orgId)
      }
    }
  }, [time, props.timeOfLastAction])

  useEffect(() => {
    if (props.userData && props.termsDate) {
      handleCookiesAndTermsConsent(props.userData, props.termsDate)
    }
  }, [props.userData, props.termsDate])

  async function handleCookiesAndTermsConsent(userData, termsDate) {
    if (userData && termsDate) {
      await handleTermsConsent(userData, termsDate)
      handleCookieConsent(userData)
    }
  }

  function adjustConsent(type, value) {
    if (props.user.uid) {
      const dbUrl = `users/${props.user.uid}`
      const dbRef = props.database.ref(dbUrl)
      dbRef.update({[type]: value})
          .then(() => {
            props.storePayload({
              userData: {
                ...props.userData,
                [type]: value,
              },
            })
          })
          .catch((error) => console.log('Error: ', error))
    }
  }

  function handleCookieConsent(userData) {
    const cookiesConsent = userData.consentMode
    if (!cookiesConsent) {
      setIsCookiesConsentModal(true)
      setIsCookiesConsentModalShowing(true)
    } else {
      if (cookiesConsent['analytics_storage'] === 'granted') {
        props.initializeAnalytics()
        if (props.isSaveCred) {
          firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        }
      }
    }
  }

  function handleTermsConsent(userData, termsDate) {
    const termsConsent = userData.termsAccepted
    return new Promise((resolve, reject) => {
      if (!termsConsent || termsConsent < termsDate) { // check for date of latest terms
        setIsTermsModal(true)
        setIsTermsModalShowing(true)
      } else {
        resolve(true)
      }
    })
  }

  /*
      Check to see if user is logged in with firebase persistance
      once firestore is ready
  */
  useEffect(() => {
    if (Object.keys(props.firestore).length) {
      listenForAuthChange()
    }
  }, [props.firestore])

  useEffect(() => {
    if (props.user && !props.isVerified) {
      checkUserVerification.current = setInterval(() => {
        props.user.reload().then(() => {
          const refreshUser = firebase.auth().currentUser
          if (refreshUser && refreshUser.emailVerified) {
            props.storePayload({user: refreshUser, isVerified: true})
            clearInterval(checkUserVerification.current)
          }
        })
      }, 5000)
    }
  }, [props.user])

  useEffect(() => {
    if (props.authKey && props.user &&
      props.usersObj?.[props.user.uid] &&
      (props.usersObj[props.user.uid].role === 'admin' ||
        props.usersObj[props.user.uid].permissions?.controlSubOrgs)
    ) {
      props.getSubOrganizationMetrics(props.database, props.orgId, props.user.uid)
    }
  }, [props.authKey, props.usersObj, props.user])

  // useEffect(() => {
  //   if (props.orgId) {
  //     if (!props.logs) {
  //       props.getLogs(props.database, props.orgId)
  //     }
  //     if (!props.metrics) {
  //       props.getMetrics(props.database, props.orgId)
  //     }
  //   }
  // }, [props.orgId])

  /* Show Welcome message on component mount */
  // useEffect(() => {
  //   props.storePayload({
  //     userMessage: 'Welcome!',
  //     notificationType: 1,
  //     notificationIcon: 'check',
  //   })
  // }, [])

  /* Automatically close type 2 notifications */
  useEffect(() => {
    if (props.notificationType === 2) {
      setTimeout(onClickNotification, 6000)
    }
  }, [props.notificationType])

  function listenForAuthChange() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        /* User is signed in, see docs for a list of available properties
           https://firebase.google.com/docs/reference/js/firebase.User
        */
        // const uid = user.uid
        /* Call relevant action to fetch user data */
        // eslint-disable-next-line max-len
        // avoids setting props.isSignedIn to true until the account reset process is complete
        props.storePayload({
          isSignedIn: window.location.pathname.includes(
              '/account/resetPassword',
          ) ?
            false :
            true,
          hasAttemptedSignIn: true,
        })
        // skips onSignInSuccess until account reset process is complete
        if (!window.location.pathname.includes('/account/resetPassword')) {
          handleCookiesAndTermsConsent()
          props.onSignInSuccess(user, props.database)
        }
      } else {
        /* User is not signed in */
        props.storePayload({
          isSignedIn: false,
          hasAttemptedSignIn: true,
          isLoading: false,
        })
      }
    })
  }

  const consentModalTextInitial = 'We value your trust. Our Terms of Service and Privacy Policy explain how we handle your data and protect your privacy. To continue, please read and accept these terms.'

  const consentModalTextUpdate = 'We have updated our Terms of Service and Privacy Policy. They explain how we handle your data and protect your privacy. To continue, please read and accept these terms.'

  const getConsentModalContent = (consentModelText) => {
    return (
      <div className='consent-modal-content'>
        <p>
          {consentModelText}
        </p>
        <br/>
        <br/>
        <a href='https://www.lazarusforms.com/legal#terms-and-conditions' target='_blank' rel='noopener noreferrer'>Terms and conditions</a>
        <br/>
        <a href='https://www.lazarusforms.com/legal#privacy-policy' target='_blank' rel='noopener noreferrer'>Privacy policy</a>
        <br/>
        <br/>
        <div className='checkbox-container'>
          <Switch
            label='I have read and accept the terms and conditions'
            checked={termsConditionsAgreed}
            onChange={(e) => {
              setTermsConditionsAgreed(e.target.checked)
            }}
            theme={props.theme}
            type='checkbox'
          />
        </div>
        <br/>
        <div className='checkbox-container'>
          <Switch
            label='I have read and accept the privacy policy'
            checked={privacyPolicyAgreed}
            onChange={(e) => {
              setPrivacyPolicyAgreed(e.target.checked)
            }}
            theme={props.theme}
            type='checkbox'
          />
        </div>
      </div>
    )
  }

  const getConsentModal = (consentModelText) => {
    return (<Modal
      content={getConsentModalContent(consentModelText)}
      isVisible={isTermsModalShowing}
      isConfirmReady={true}
      confirmText='Continue'
      onConfirm={() => {
        if (termsConditionsAgreed && privacyPolicyAgreed) {
          setIsTermsModalShowing(false)
          setTimeout(() => {
            adjustConsent('termsAccepted', Date.now())
          }, 250)
        } else {
          props.storePayload({
            userMessage: 'You must read and accept the terms and conditions and the privacy policy before continuing.',
            notificationType: 1,
            notificationIcon: 'warning',
          })
        }
      }}
      showClose={false}
      confirmButtonType={8}
      theme={props.theme}
    />)
  }

  const wrapComponent = (component) => (
    <>
      {props.user === undefined || props.termsDate === undefined ? (
        <>
          {props.isLoading && <FullLoadingScreen theme={props.theme} />}
          {props.isLoadingRequest && <RequestLoadingScreen />}
        </>
      ) : !props.userData?.termsAccepted ? (
        getConsentModal(consentModalTextInitial)
      ) : (props.termsDate && props.userData?.termsAccepted < props.termsDate) ? (
        getConsentModal(consentModalTextUpdate)
      ): !props.user.emailVerified ? (
        <Template {...props} component={AwaitingVerification} />
      ) : props.isWelcome ? (
        <Template {...props} component={Welcome} />
      ) : ((!props.usersObj?.[props.user.uid]?.permissions?.viewLogs && props.usersObj?.[props.user.uid]?.role !== 'admin') && component === Dashboard) ? (
        <Navigate to='/demo' />
      ) : (<>
        {props.isLoading && <FullLoadingScreen theme={props.theme} />}
        {props.isLoadingRequest && <RequestLoadingScreen />}
        <Template {...props} component={component} />
      </>
      )}
    </>
  )

  // function wrapComponent(component) {
  //   if (isLoading || !props.hasAttemptedSignIn) {
  //     return (
  //       <FullLoadingScreen />
  //     )
  //   } else if (!props.isSignedIn && props.hasAttemptedSignIn) {
  //     return (<Auth />)
  //   } else if (props.user && !props.user.emailVerified) {
  //     return (
  //       <Template {...props} component={AwaitingVerification} />
  //     )
  //   } else if (props.isWelcome) {
  //     return (
  //       <Template {...props} component={Welcome} />
  //     )
  //   } else {
  //     return (
  //       <Template {...props} component={component} />
  //     )
  //   }
  // }

  const authPageAppStyle = {
    backgroundImage: `url(${Background})`,
  }

  const billingNotifcationModal = (
    <Modal
      isVisible={showBillingModal}
      theme={props.theme}
      showClose={false}
      onCancel={() => setShowBillingModal(false)}
      onConfirm={(e) => {
        e.preventDefault()
        setShowBillingModal(false)
        if (!props.customerPortal) {
          const anchorElem = document.getElementById('customer-portal')
          props.getCustomerPortal(
              props.orgId,
              props.user.uid,
              props.user.email,
              setShowBillingModal,
              anchorElem,
          )
        }
      }}
      cancelText='Close'
      confirmText='Subscribe'
      showCancel
    >
      <div>
        <h2>Look at you go!</h2>
        <p>
          You&apos;ve used up all your free credits. Subscribe to continue using
          Lazarus Forms.
        </p>
      </div>
    </Modal>
  )

  const termsOfUseModalContent = (
    <div className='consent-modal-content'>
      <div className='consent-body show-scroll-bar'>
        <p>
        We value your trust. Our Terms of Service and Privacy Policy explain how we handle your data and protect your privacy. To continue, please accept these terms.
        </p>
        <a href='https://www.lazarusforms.com/legal#terms-and-conditions' target='_blank' rel='noopener noreferrer'>Terms and conditions</a>
        <a href='https://www.lazarusforms.com/legal#privacy-policy' target='_blank' rel='noopener noreferrer'>Privacy policy</a>
      </div>
      <div className='buttons'>
        <button
          style={{marginTop: '1em'}}
          className='custom-btn subscribe-btn'
          onClick={() => {
            adjustConsent('termsAccepted', Date.now())
            setIsTermsModalShowing(false)
          }}
        >
        Yes, I accept
        </button>
        {/* <button
          style={{marginTop: '1em'}}
          className='custom-btn close-btn'
          onClick={() => {
            setIsTermsModalShowing(false)
            console.log('To do: prevent access to app')
          }}
        >
        Cancel
        </button> */}
      </div>
    </div>
  )

  function acceptCookies(e) {
    adjustConsent('consentMode', {
      'analytics_storage': 'granted',
      'ad_storage': 'denied',
    })
    setIsCookiesConsentModalShowing(false)
    if (props.isSaveCred) {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    }
  }

  function denyCookies(e) {
    e.preventDefault()
    setIsCookiesConsentModalShowing(false)
    adjustConsent('consentMode', {
      'analytics_storage': 'denied',
      'ad_storage': 'denied',
    })
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
    sessionStorage.clear()
  }

  function formatOTPInput(input) {
    // numbers only
    if (isNaN(input[input.length - 1])) return input.slice(0, -1)
    // auto add '-'
    if (input.length === 4) return input.slice(0, 3) + '-' + input.slice(3, 4)
    // no longer than 6 digits (not counting dash)
    if (input.length > 7) return input.slice(0, 7)
    return input
  }

  function isValidOTP(input) {
    return (input.length === 7 && !isNaN(input.slice(0, 3) && !isNaN(input.slice(4, 7))))
  }

  const enrollMFAContentPage1 = (
    <div className='display-block'>
      <p className='text-xl'>
      Enable Two-Factor Authentication
      </p>
      <p>
      Scan the QR code below with your authentication app to set up Two-Factor Authentication.
      </p>
      <br /><br /><br /><br /><br />
      <span className='align-center justify-center'>
        <QRCodeSVG
          value={props.QRCodeUrl}
          includeMargin={true}
          size={196}
        />
      </span>
    </div>
  )

  const enrollMFAContentPage2 = (
    <div className='display-block'>
      <p className='text-xl'>
      Enable Two-Factor Authentication
      </p>
      <p>
      To complete the setup, please enter the One Time Passcode generated by your authentication app.
      </p>
      <br /><br /><br /><br /><br />
      <Input
        type={1}
        label='One Time Passcode'
        placeholder = '000-000'
        value={MFAOTPInput}
        onChange={(e) => setMFAOTPInput(formatOTPInput(e.target.value))}
        theme={props.theme}
      />
      <a className='text-small' href='https://lazarus-ai.atlassian.net/servicedesk/customer/portal/5' target='_blank' rel='noopener noreferrer'>
        Having trouble?
      </a>
    </div>
  )

  const reauthenticateContent = (
    <div className='display-block'>
      <p className='text-xl'>
        {props.reauthCaller === 'enroll' ? 'Enable' : 'Disable'} Two-Factor Authentication
      </p>
      <p>
      To {props.reauthCaller === 'enroll' ? 'enable' : 'disable'} Two-Factor Authentication (2FA) and add an extra layer of security to your account, we need to verify your identity.
      </p>
      <br /><br /><br /><br /><br />
      <Input
        label='Re-enter Password'
        inputType={showSignInPassword ? 'text' : 'password'}
        type={1}
        value={reauthPassword}
        iconLeft={<Icon icon='lock-outline' />}
        iconRight={
          <Icon
            icon={showSignInPassword ? 'eye-off-2-outline' : 'eye-outline'}
            key={showSignInPassword ? 'show-sign-in' : 'no-show-sign-in'}
            onClick={(e) => {
              setShowSignInPassword(!showSignInPassword)
            }}
            className='password-change-icon'
          />
        }
        theme={props.theme}
        onChange={(e) => setReauthPassword(e.target.value)}
      />
    </div>
  )

  const reAuthOTPContent = (
    <div className='display-block'>
      <p className='text-xl'>
        Disable Two-Factor Authentication
      </p>
      <p>
      To complete the setup, please enter the One Time Passcode generated by your authentication app.
      </p>
      <br /><br /><br /><br /><br />
      <Input
        inputType='text'
        type={1}
        value={reauthOTPInput}
        label='One Time Passcode'
        placeholder = '000-000'
        theme={props.theme}
        onChange={(e) => setReauthOTPInput(formatOTPInput(e.target.value))}
      />
    </div>
  )

  return (
    <Fragment>
      <Notification
        theme={props.theme}
        type={props.notificationType || 1}
        icon={(
          <div className={`toggle-container ${props.notificationIcon === 'check' ? 'toggled' : ''}`}>
            <Icon
              fill='var(--colors-brand-state-success-contrast-dark)'
              icon='checkmark-outline'
            />
            <Icon
              fill='var(--colors-brand-state-warn-contrast-dark)'
              icon='alert-circle-outline'
            />
          </div>
        )}
        content={props.userMessage}
        time={Helpers.timeStampToTime(Date.now())}
        isVisible={props.isNotificationVisible && props.userMessage}
        onClick={onClickNotification}
        style={
            props.notificationType === 3 ?
              {zIndex: 101, width: '30em', whiteSpace: 'pre-wrap'} :
              {zIndex: 101, whiteSpace: 'pre-wrap'}
        }
        className={'white-space-pre-line'}
      />
      {isCookiesConsentModal &&
      <>
        <CookiesModal
          isShowing={isCookiesConsentModalShowing}
          acceptFunction={acceptCookies}
          denyFunction={denyCookies}
        />
      </>
      }
      {isCookiesConsentModal &&
      <>
        <CookiesModal
          isShowing={isCookiesConsentModalShowing}
          acceptFunction={acceptCookies}
          denyFunction={denyCookies}
        />
      </>
      }
      {isTermsModal &&
      <>
        <Modal
          component={termsOfUseModalContent}
          isShowing={isTermsModalShowing}
          customStyle={{maxHeight: '100%'}}
          isConfirmReady={true}
        />
        {isTermsModalShowing &&
          <div
            id='modal-backdrop'
          />
        }
      </>
      }
      {
        !window.location.pathname.includes('/account/resetPassword') &&
        <Auth />
      }
      {showBillingModal &&
        // <Notification
        //   type={true}
        //   image={Warning}
        //   message={'oof'}
        //   time={Helpers.timeStampToTime(Date.now())}
        //   isVisible={true}
        //   onClick={() => {
        //     setShowBillingModal(false)
        //   }}
        //   style={
        //     props.notificationType === 3 ?
        //     {zIndex: 100, width: '30em'} :
        //     {zIndex: 100}
        //   }
        // />
        <div className='custom-modal center-modal'>
          {billingNotifcationModal}
        </div>
      }
      {props.isReauthenticateModal &&
        <>
          <Modal
            confirmText='Confirm'
            cancelText='Cancel'
            isVisible={props.isReauthenticateModalShowing}
            theme={props.theme}
            height='90vh'
            width='32rem'
            onConfirm = {() => {
              if (!reauthPassword) {
                props.storePayload({
                  userMessage: 'Please re-enter your password.',
                  notificationIcon: 'warning',
                  notificationType: 3,
                })
              } else {
                setReauthPassword('')
                props.storePayload({
                  reauthPassword: reauthPassword,
                })
              }
            }}
            onCancel = {() => {
              setReauthPassword('')
              props.storePayload({
                isReauthenticateModalShowing: false,
              })
            }}
          >
            {reauthenticateContent}
          </Modal>
          {props.isReauthenticateModal &&
            <div id='modal-backdrop'></div>
          }
        </>
      }
      {props.isReauthOTPModal &&
        <>
          <Modal
            confirmText='Confirm'
            cancelText='Cancel'
            isVisible={props.isReauthOTPModalShowing}
            confirmDisabled={!isValidOTP(reauthOTPInput)}
            theme={props.theme}
            height='90vh'
            width='32rem'
            onConfirm = {() => {
              setReauthOTPInput('')
              props.storePayload({
                reauthOTPInput: reauthOTPInput.slice(0, 3) + reauthOTPInput.slice(4, 7),
              })
            }}
            onCancel = {() => {
              setReauthOTPInput('')
              props.storePayload({
                isReauthOTPModalShowing: false,
              })
            }}
          >
            {reAuthOTPContent}
          </Modal>
          {props.isReauthOTPModal &&
            <div id='modal-backdrop'></div>
          }
        </>
      }
      {
        props.isMFAModal &&
        <>
          <Modal
            confirmText='Continue'
            cancelText={props.mfaModalPage === 1 ? 'Cancel' : 'Back'}
            showCancel={true}
            showClose={false}
            isVisible={props.isMFAModalShowing}
            height='90vh'
            width='32rem'
            confirmDisabled={props.mfaModalPage === 1 ? false : !isValidOTP(MFAOTPInput)}
            theme={props.theme}
            onConfirm = {() => {
              if (props.mfaModalPage === 1) {
                props.storePayload({
                  mfaModalPage: 2,
                })
              } else {
                setMFAOTPInput('')
                props.storePayload({
                  MFAOTPInput: MFAOTPInput.slice(0, 3) + MFAOTPInput.slice(4, 7),
                })
              }
            }}
            onCancel = {() => {
              if (props.mfaModalPage === 1) {
                setMFAOTPInput('')
                props.storePayload({
                  isMFAModalShowing: false,
                })
              } else {
                props.storePayload({
                  mfaModalPage: 1,
                })
              }
            }}
          >
            {props.mfaModalPage === 1 ? enrollMFAContentPage1 : enrollMFAContentPage2}
          </Modal>
          {props.isMFAModal &&
            <div id='modal-backdrop'></div>
          }
        </>
      }
      <div
        className={`App ${!props.isSignedIn ? 'auth' : ''}`}
        style={!props.isSignedIn ? authPageAppStyle : {}}
      >
        <BrowserRouter>
          <Routes>
            <Route exact path='/' element={wrapComponent(Dashboard)} />
            <Route path='/auth' element={<ForgotPassword />} />
            <Route path='/quickstart' element={wrapComponent(Quickstart)} />
            <Route path='/docs' element={wrapComponent(Documentation)} />
            <Route path='/api' element={wrapComponent(Api)} />
            <Route path='/settings' element={wrapComponent(Settings)} />
            <Route path='/training' element={wrapComponent(Training)} />
            <Route path='/demo' element={wrapComponent(DemoWrapper)} />
            <Route path='/vkg' element={wrapComponent(VKGDashboard)} />
            <Route path='/models' element={wrapComponent(Models)} />
            <Route path='/suborganizations' element={wrapComponent(SubOrgDashboard)} />
            <Route path='/suborganizations/:subOrgId' element={wrapComponent(SubOrgView)} />
            <Route
              path='/account/resetPassword'
              element={<AccountInitialization />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state, ownProps) => ({
  cookies: ownProps.cookies,
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  analytics: state.firebaseReducer.analytics,
  userMessage: state.userReducer.userMessage,
  notificationType: state.userReducer.notificationType,
  notificationIcon: state.userReducer.notificationIcon,
  isSignedIn: state.userReducer.isSignedIn,
  hasAttemptedSignIn: state.userReducer.hasAttemptedSignIn,
  isNew: state.userReducer.isNew,
  isLoading: state.userReducer.isLoading,
  isLoadingRequest: state.userReducer.isLoadingRequest,
  orgId: state.userReducer.orgId,
  user: state.userReducer.user,
  userData: state.userReducer.userData,
  usersObj: state.userReducer.usersObj,
  isVerified: state.userReducer.isVerified,
  isNotificationVisible: state.userReducer.isNotificationVisible,
  isFirstTimeUser: state.userReducer.isFirstTimeUser,
  isWelcome: state.userReducer.isWelcome,
  billing: state.userReducer.billing,
  customerPortal: state.userReducer.customerPortal,
  theme: state.userReducer.theme,
  analytics: state.firebaseReducer.analytics,
  isSaveCred: state.userReducer.isSaveCred,
  termsDate: state.userReducer.termsDate,
  isMFAModal: state.userReducer.isMFAModal,
  isMFAModalShowing: state.userReducer.isMFAModalShowing,
  QRCodeUrl: state.userReducer.QRCodeUrl,
  isReauthenticateModal: state.userReducer.isReauthenticateModal,
  isReauthenticateModalShowing: state.userReducer.isReauthenticateModalShowing,
  reauthCaller: state.userReducer.reauthCaller,
  isReauthOTPModal: state.userReducer.isReauthOTPModal,
  isReauthOTPModalShowing: state.userReducer.isReauthOTPModalShowing,
  timeOfLastAction: state.userReducer.timeOfLastAction,
  time: state.userReducer.time,
  mfaModalPage: state.userReducer.mfaModalPage,
  authKey: state.userReducer.authKey,
})

export default connect(
    mapStateToProps,
    {
      createFirebaseApp,
      storePayload,
      onSignInSuccess,
      getCustomerPortal,
      initializeAnalytics,
      getVersionDetails,
      signOut,
      getSubOrganizationMetrics,
    },
)(App)
