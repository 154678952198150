/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, {useState, useEffect, useRef} from 'react'
import Code from './Code'
import Profile from './Profile'
import Roles from './Roles'
import Helpers from '../Helpers.js'
import defaultPerms from '../defaultPerms.json'
import {
  Icon,
  MetricCard,
  Table,
  TableEntry,
  TabContainer,
  Avatar,
  Button,
  Input,
  Card,
  Modal,
} from '@lazarusai/forms-ui-components'
import PermissionsModal from './PermissionsModal'
import {getUsers} from '../actions/getUsers.js'
import {logAnalyticsEvent} from '../actions/logAnalyticsEvent.js'
import {getSubOrgs} from '../actions/getSubOrgs.js'
import {storePayload} from '../actions/storePayload.js'
import {getCustomerPortal} from '../actions/getCustomerPortal.js'
import {getOrgActionLogs} from '../actions/getOrgActionLogs.js'
import {connect} from 'react-redux'
import '../styles/Settings.css'
// import Table from './Table.jsx'
// import {InputMulti} from '@lazarusai/forms-ui-components'
// import '@lazarusai/forms-ui-components/dist/index.css'
import endpoints from '../endpoints.json'
import blockedEmails from '../blockedEmails.json'

import PermissionSideTab from './PermissionSideTab'

function Settings(props) {
  const [isSettingsLoading, setIsSettingsLoading] = useState(true)
  // isSettingsLoading2 is used when you still want to keep content rendered
  const [permissionsModalShowing, setPermissionsModalShowing] = useState(false)
  const [permissionsID, _setPermissionsID] = useState(null)
  const [permissionsDict, setPermissionsDict] = useState({})
  const permissionsIDRef = useRef(null)

  function setPermissionsID(val) {
    _setPermissionsID(val)
    permissionsIDRef.current = val
  }

  const [canInviteUser, setCanInviteUser] = useState(false);
  const [canAccessOrganization, setCanAccessOrganization] = useState(false)

  const [permissionsEmail, setPermissionsEmail] = useState(null)
  const [permissionsUser, setPermissionsUser] = useState(null)
  const [collapsedPermissionSections, setCollapsedPermissionSections] = useState([])
  const [changedUserPermissions, setChangedUserPermissions] = useState({})
  const [authKeyResetInput, setAuthKeyResetInput] = useState('')
  const [renderedOrgActionLogs, _setRenderedOrgActionLogs] = useState(
      props.orgActionLogs,
  )
  const [selectedAuditEntry, setSelectedAuditEntry] = useState(0)
  const [selectedSubOrg, setSelectedSubOrg] = useState(0)
  const [isResetAuthKeyModal, setIsResetAuthKeyModal] = useState(false)
  const [isResetAuthKeyModalShowing, setIsResetAuthKeyModalShowing] =
    useState(false)
  const renderedOrgActionLogsRef = useRef(renderedOrgActionLogs)
  const [entries, setEntries] = useState([])
  const [auditLogEntries, setAuditLogEntries] = useState([])
  const [inviteEmail, setInviteEmail] = useState('')

  // for tabs
  const [activeTab, setActiveTab] = useState('profile')


  function setRenderedOrgActionLogs(data) {
    _setRenderedOrgActionLogs(data)
    renderedOrgActionLogsRef.current = data
  }

  useEffect(() => {
    /*
      Warning: I only check for users, then fetch all necessary
      data for this page accordingly. I haven't determined whether
      there are situations where users don't fetch but other data does
    */
    if (!props.users && props.orgId) {
      setIsSettingsLoading(true)
      props.getSubOrgs(props.database, props.orgId)
      props.getOrgActionLogs(props.database, props.orgId)
      // props.getCustomerPortal(props.orgId, props.user.uid, props.user.email)
      // props.getBilling(props.database, props.orgId)
    }
  }, [props.users, props.orgId])

  function onChangeForm(e) {
    if (!isBusinessEmail(e.target.value)) {
      props.storePayload({
        userMessage: 'Users must have business emails',
        notificationType: 2,
        notificationIcon: 'warning',
      })
    }
    setInviteEmail(e.target.value)
  }

  useEffect(() => {
    if (props.orgActionLogs) {
      setRenderedOrgActionLogs(props.orgActionLogs)
    } else {
      props.getOrgActionLogs(props.database, props.orgId)
    }
  }, [props.orgActionLogs])

  useEffect(() => {
    const userDefaultPermissions = props.usersObj?.[permissionsIDRef.current]?.permissions
    const perms = {
      viewLogs: {
        roles: defaultPerms['viewLogs']['roles'],
        description: defaultPerms['viewLogs']['description'],
        icon: <Icon icon='star-outline'></Icon>,
        default: userDefaultPermissions?.['viewLogs'],
      },
      viewLogData: {
        roles: defaultPerms['viewLogData']['roles'],
        description: defaultPerms['viewLogData']['description'],
        icon: <Icon icon='settings-outline'></Icon>,
        default: userDefaultPermissions?.['viewLogData'],
      },
      useDemoCredits: {
        roles: defaultPerms['useDemoCredits']['roles'],
        description: defaultPerms['useDemoCredits']['description'],
        icon: <Icon icon='settings-outline'></Icon>,
        default: userDefaultPermissions?.['useDemoCredits'],
      },
      viewCredentials: {
        roles: defaultPerms['viewCredentials']['roles'],
        description: defaultPerms['viewCredentials']['description'],
        icon: <Icon icon='flash-outline'></Icon>,
        default: userDefaultPermissions?.['viewCredentials'],
      },
      manageBilling: {
        roles: defaultPerms['manageBilling']['roles'],
        description: defaultPerms['manageBilling']['description'],
        icon: <Icon icon='flash-outline'></Icon>,
        default: userDefaultPermissions?.['manageBilling'],
      },
      inviteUser: {
        roles: defaultPerms['inviteUser']['roles'],
        description: defaultPerms['inviteUser']['description'],
        icon: <Icon icon='flash-outline'></Icon>,
        default: userDefaultPermissions?.['inviteUser'],
      },
      setPermissions: {
        roles: defaultPerms['setPermissions']['roles'],
        description: defaultPerms['setPermissions']['description'],
        icon: <Icon icon='flash-outline'></Icon>,
        default: userDefaultPermissions?.['setPermissions'],
      },
      resetAuthKey: {
        roles: defaultPerms['resetAuthKey']['roles'],
        description: defaultPerms['resetAuthKey']['description'],
        icon: <Icon icon='flash-outline'></Icon>,
        default: userDefaultPermissions?.['resetAuthKey'],
      },
      viewAuditLogs: {
        roles: defaultPerms['viewAuditLogs']['roles'],
        description: defaultPerms['viewAuditLogs']['description'],
        icon: <Icon icon='flash-outline'></Icon>,
        default: userDefaultPermissions?.['viewAuditLogs'],
      },
      controlSubOrgs: {
        roles: ['admin'],
        description: 'Manage Sub Org access/billing/etc.',
        icon: <Icon icon='flash-outline'></Icon>,
        default: userDefaultPermissions?.['controlSubOrgs'],
      },
      readVKG: {
        roles: defaultPerms['readVKG']['roles'],
        description: defaultPerms['readVKG']['description'],
        icon: <Icon icon='eye-outline'></Icon>,
        default: userDefaultPermissions?.['readVKG'],
      },
      writeVKG: {
        roles: defaultPerms['writeVKG']['roles'],
        description: defaultPerms['writeVKG']['description'],
        icon: <Icon icon='edit-outline'></Icon>,
        default: userDefaultPermissions?.['writeVKG'],
      },
      // removeAccounts: {
      //   roles: ['admin'],
      //   description: 'Remove account',
      //   icon: <Icon icon='flash-outline'></Icon>,
      //   default: userDefaultPermissions?.['removeAccounts'],
      // },
    }
    if (!props.vkgDomain) {
      delete perms.readVKG
      delete perms.writeVKG
    }
    if (!props.orgDetails.permissions?.canCreateSubOrg) {
      delete perms.controlSubOrgs
    }
    setPermissionsDict(perms)
  }, [props.vkgDomain])


  const currentUser = props.user && props.users && props.users.filter((user) => user.id === props.user.uid)[0]

  const baseEvent = {
    'user_id': props.user?.uid,
    'page': window.location.pathname.substring(1),
    'time': Date.now(),
    'user': window.navigator.userAgent,
  }

  const apiEvent = {
    ...baseEvent,
    'event_type': 'api',
  }

  const clickEvent = {
    ...baseEvent,
    'event_type': 'click',
  }

  function onClickSendInvite(e) {
    e.preventDefault()
    setPermissionsID(inviteEmail)
    setPermissionsEmail(inviteEmail)
    /* clear user if stored from existing user permission change
      (same modal rendered for invite and existing user permission change)
    */
    setPermissionsUser(null)
    setPermissionsModalShowing(true)
  }

  function onClickResetAuthKey() {
    props.logAnalyticsEvent('attempt_auth_key_reset', clickEvent)
    setIsResetAuthKeyModal(true)
    setIsResetAuthKeyModalShowing(true)
  }


  // function adjustConsent(type, value) {
  //   if (props.user.uid) {
  //     const dbUrl = `users/${props.user.uid}`
  //     const dbRef = props.database.ref(dbUrl)
  //     dbRef.update({[type]: value})
  //         // .then()
  //         .catch((error) => console.log('Error: ', error))
  //   }
  // }

  // function onChangeCookiesPermission() {
  //   const value = {
  //     ...props.userData.consentMode,
  //     analytics_storage:
  //       !props.userData.consentMode?.analytics_storage || props.userData.consentMode?.analytics_storage == 'denied' ?
  //         'granted' :
  //         'denied',
  //   }
  //   console.log('To do: change db')
  //   adjustConsent('consentMode', value)
  //   props.storePayload({
  //     userData: {
  //       ...props.userData,
  //       consentMode: value,
  //     },
  //   })
  // }

  function onConfirmResetAuthKey(e) {
    e.preventDefault()
    if (authKeyResetInput.toUpperCase() !== 'I UNDERSTAND') {
      props.storePayload({
        userMessage: 'Please read the instructions',
        notificationIcon: 'warning',
        notificationType: 3,
      })
      props.logAnalyticsEvent('failed_auth_key_reset', clickEvent)
      return
    }
    // const url = process.env.REACT_APP_URL + 'auth-key-reset'
    const url = process.env.REACT_APP_URL + endpoints.resetAuthKey
    const data = {
      org_id: props.orgId,
      user_id: props.user.uid,
      user_email: props.user.email,
    }
    setIsResetAuthKeyModalShowing(false)
    Helpers.fetchPostJson(url, data)
        .then((data) => {
          props.storePayload({
            userMessage: 'Auth key sucessfully changed',
            notificationType: 1,
            notificationIcon: 'check',
          })
          props.logAnalyticsEvent('completed_auth_key_reset', apiEvent)
        })
        .catch((data) => {
          console.log('error', data)
          props.storePayload({
            userMessage: 'Auth key change failure',
            notificationType: 3,
            notificationIcon: 'warning',
          })
        })
        .finally(() => {
          setAuthKeyResetInput('')
        })
  }

  function onCancelResetAuthKey(e) {
    e.preventDefault()
    setIsResetAuthKeyModalShowing(false)
  }

  function changeUserPermissions(permissions, id, currentRole, vkgAccess) {
    const newUsersObj = props.usersObj
    newUsersObj[id].role = currentRole
    props.storePayload({usersObj: newUsersObj})
    // const url = process.env.REACT_APP_URL + 'update/user'
    const url = process.env.REACT_APP_URL + endpoints.updateUser
    const data = {
      userId: id,
      orgId: props.orgId,
      permissions: permissions,
      vkgAccess: Helpers.encodeVKGAccess(vkgAccess),
      role: currentRole,
    }
    const headers = {
      'orgId': props.orgId,
      'userId': props.user.uid,
      'authKey': props.authKey,
    }
    return Helpers.fetchPostJsonWithStatus(url, data, headers)
  }

  const permissions = props.usersObj?.[props.user.uid]?.permissions

  const savePermissions = (value, id, currentRole, vkgAccess) => {
    setPermissionsModalShowing(false)

    if (id === inviteEmail && inviteEmail.length > 0) {
      // const url = process.env.REACT_APP_URL + 'invite'
      const url = process.env.REACT_APP_URL + endpoints.invite
      const data = {
        org_id: props.orgId,
        user_id: props.user.uid,
        email: inviteEmail?.toLowerCase(),
        role: currentRole.toLowerCase(),
        permissions: value,
        vkgAccess: Helpers.encodeVKGAccess(vkgAccess),
      }
      const headers = {
        'orgId': props.orgId,
        'userId': props.user.uid,
        'authKey': props.authKey,
      }
      Helpers.fetchPostJsonWithStatus(url, data, headers)
          .then(async ([resPromise, status]) => {
            const data = await resPromise
            if (status >= 200 && status < 300) {
              props.storePayload({
                userMessage:
            `User was successfully added. They should receive an email with a link to reset their password${data.error ? ': '+Helpers.parseErrorMessages(data.error).join(', ') : '.'}`,
                notificationType: 1,
                notificationIcon: 'check',
              })
              setInviteEmail('')
            } else {
              props.storePayload({
                userMessage: `Error inviting user${data.error ? ': '+Helpers.parseErrorMessages(data.error).join(', ') : '.'}`,
                notificationType: 2,
                notificationIcon: 'warning',
              })
            }
          })
          .catch((data) => {
            console.log(data)
            props.storePayload({
              userMessage: 'Error inviting user.',
              notificationType: 2,
              notificationIcon: 'warning',
            })
          })
    } else { // regular permission change
      changeUserPermissions(value, id, currentRole.toLowerCase(), vkgAccess)
          .then(async ([resPromise, status]) => {
            const res = await resPromise
            if (status >= 200 && status < 300) {
              props.storePayload({
                userMessage: `Permissions updated successfully${res.message ? ': '+res.message : '.'}`,
                notificationType: 2,
                notificationIcon: 'check',
              })
            } else {
              props.storePayload({
                userMessage: `Error updating permissions${res.message ? ': '+res.message : '.'}`,
                notificationType: 1,
                notificationIcon: 'warning',
              })
            }
          })
          .catch((error) => {
            console.log(error)
            props.storePayload({
              userMessage:
        'Error updating permissions.',
              notificationType: 1,
              notificationIcon: 'warning',
            })
          })
    }
  }

  useEffect(() => {
    if (props.customerPortal) {
      const anchorElem = document.getElementById('customer-portal')
      if (anchorElem) {
        anchorElem.setAttribute('href', props.customerPortal)
      }
    }
  }, [props.customerPortal])

  // User Table Entries Use Effect
  useEffect(() => {
    const newEntries = props.usersObj ?
      Object.keys(props.usersObj).map((userId, i) => {
        const user = props.usersObj[userId]
        return (
          <TableEntry
            key={'user-' + i}
            content={[
              <span key='email' className='text-bright-blue'>
                {user['email']}
              </span>,
              <span key='date'>{user['datetimeCreated']}</span>,
              <Button
                key='role'
                theme={props.theme}
                className='role-button capitalize'
                type={8}
                text={user['role']}
                icon={<Icon icon='arrow-right-outline' />}
                iconPosition='right'
                iconJustify='edge'
                disabled={userId === props.user?.uid}
                onClick={() => {
                  setPermissionsID(userId)
                  setPermissionsEmail(user.email)
                  setPermissionsUser(user)
                  setPermissionsModalShowing(true)
                }}
              ></Button>,
            ]}
          />
        )
      }) : []
    setEntries(newEntries)
  }, [props.usersObj])

  // Audit Log Entries Use Effect
  useEffect(() => {
    const newAuditLogEntries = props.subOrgs ?
      Object.keys(props.subOrgs).map((subOrgId, i) => {
        const subOrg = props.subOrgs[subOrgId]
        return (
          <tr
            className={`entry-wrapper ${
              selectedSubOrg === i + 1 && 'active'
            }`}
            key={'entry-' + i}
          >
            <td
              className='entry'
              onClick={() => {
                if (subOrg.metadata) {
                  if (selectedSubOrg === i + 1) {
                    setSelectedSubOrg(0)
                  } else {
                    setSelectedSubOrg(i + 1)
                  }
                }
              }}
            >
              <Avatar
                name={subOrg.name}
                size='small'
                showGradient
                showText={false}
              ></Avatar>
              <div className='audit-log-right'>
                <p className='white-text'>{subOrg.name}</p>
                <p className='blue-text'>{subOrg.subOrgId}</p>
              </div>
              {subOrg.metadata && (
                <div
                  className={`toggle-container ${
                    selectedSubOrg === i + 1 && 'toggled'
                  }`}
                >
                  <Icon icon='chevron-down-outline' />
                  <Icon icon='chevron-right-outline' />
                </div>
              )}
            </td>

            {selectedSubOrg && i === selectedSubOrg - 1 ? (
              <td
                className={
                  selectedSubOrg && i === selectedSubOrg - 1 ?
                    'full-log height-100' :
                    'full-log height-0'
                }
              >
                <Code
                  theme={props.theme}
                  code={JSON.stringify(subOrg.metadata)}
                ></Code>
              </td>
            ) : (
              <td />
            )}
          </tr>
        )
      }) : []
    setAuditLogEntries(newAuditLogEntries)
  }, [props.subOrgs])

  // Settings Loading Use Effect
  const settingsPageDependencies = [props.usersObj, props.user, props.customRoles]
  useEffect(() => {
    if (props.usersObj && props.user && props.customRoles) {
      const newCanAccessOrganization =
        props.usersObj?.[props.user.uid]?.role === 'admin' ||
        permissions && organizationPermissions.every((permission) => {
          return permissions[permission]
        })
      setCanAccessOrganization(newCanAccessOrganization)
      setCanInviteUser((!permissions && props.usersObj?.[props.user.uid]?.role === 'admin') || permissions?.['inviteUser'])
      setIsSettingsLoading(false)
    }
  }, settingsPageDependencies)

  const handleProfileChanges = () => {
    if (props.profileChangesMade) {
      if (window.confirm('You have unsaved changes. Are you sure you want to continue?')) {
        props.storePayload({
          profileChangesMade: false,
        })
        window.removeEventListener('beforeunload', props.preventNavigationListener)
      } else {
        return false
      }
    }
    return true
  }

  const resetAuthKeyContent = (
    <div className='gap-group'>
      <p>
        {`Generating a new key will invalidate your previous key. Integrations that you've already set up might break. Please confirm that this is your choice by typing "I understand".`}
      </p>
      <Input
        inputType='text'
        label='Confirm'
        iconLeft={<Icon icon='palert-circle-outline' />}
        value={authKeyResetInput}
        onChange={(e) => setAuthKeyResetInput(e.target.value)}
        theme={props.theme}
      />
    </div>
  )

  const organizationPermissions = [
    'setPermissions',
    'inviteUser',
  ]

  const isBusinessEmail = (email) => {
    return !blockedEmails.blockedEmails.some((emailProvider) => email.includes(emailProvider))
  }

  const organizationTab = (
    <div
      name='Organization'
      className='tab-content org-content show-scrollbar'
    >
      {canInviteUser ?
            <Card
              title='Invite to Organization'
              titleStyle={{fontSize: 'var(--font-sizes-medium)'}}
              theme={props.theme}
            >
              <form onSubmit={onClickSendInvite}>
                <Input
                  name='inviteeEmail'
                  onChange={onChangeForm}
                  value={inviteEmail}
                  theme={props.theme}
                  label='Email'
                  type='email'
                  iconLeft={<Icon icon='person-outline' />}
                  placeholder="Type User's Email"
                  required
                />
                <Button
                  buttonType='submit'
                  text='Next'
                  theme={props.theme}
                  icon={<Icon icon='arrow-forward-outline' />}
                  iconPosition='right'
                  iconJustify='edge'
                  disabled={!isBusinessEmail(inviteEmail)}
                />
              </form>
            </Card> :
            <div></div>
      }
      <Table
        theme={props.theme}
        title='Your Permissions'
        className='user-table'
        columnTitles={['Email', 'Created', 'Role']}
        showCount={false}
        entries={[
          <TableEntry
            key={'user'}
            content={[
              <span key='email' className='text-bright-blue'>
                {!isSettingsLoading && currentUser && currentUser.email }
              </span>,
              currentUser && currentUser.datetimeCreated,

              <span key='email' className='text-success-light capitalize'>
                {currentUser && currentUser.role}
              </span>,
            ]}
          />,
        ]}
        maxHeight='16rem'
      />
      <Table
        className='org-table'
        theme={props.theme}
        title='Organization'
        total={entries.length}
        columnTitles={['Email', 'Created', 'Role']}
        entries={entries}
      />
      <div className={'sub-org-audit-log-container '+(props.subOrgs ? '': 'no-sub-orgs-container')}>
        {props.subOrgs && <Table
          theme={props.theme}
          title='Sub-orgs'
          showCount={props.subOrgs ? true : false}
          entries={auditLogEntries}
          maxHeight='16rem'
          style={{minHeight: '8rem'}}
        />}
        <Table
          title='Audit Log'
          showCount={renderedOrgActionLogs ? true : false}
          entries={
            renderedOrgActionLogs &&
                    renderedOrgActionLogs.map((log, i) => {
                      return (
                        <tr
                          className={`entry-wrapper ${
                            selectedAuditEntry === i + 1 && 'active'
                          }`}
                          key={'log' + i}
                        >
                          <td
                            className='entry'
                            onClick={() => {
                              if (log.data) {
                                if (selectedAuditEntry === i + 1) {
                                  setSelectedAuditEntry(0)
                                } else {
                                  setSelectedAuditEntry(i + 1)
                                }
                              }
                            }}
                          >
                            <Avatar
                              name={log.user}
                              size='small'
                              showGradient
                              showText={false}
                            ></Avatar>
                            <div className='audit-log-right'>
                              <p className='white-text'>{log.user}</p>
                              <p className='orange-text'>{log.event}</p>
                              <p className='gray-text'>{log.dateTimeString}</p>
                            </div>
                            {log.data && (
                              <div
                                className={`toggle-container ${
                                  selectedAuditEntry === i + 1 && 'toggled'
                                }`}
                              >
                                <Icon icon='chevron-down-outline' />
                                <Icon icon='chevron-right-outline' />
                              </div>
                            )}
                          </td>
                          {selectedAuditEntry && i === selectedAuditEntry - 1 ? (
                            <td className='full-log'>
                              <Code
                                theme={props.theme}
                                code={JSON.stringify(log.data)}
                              />
                            </td>
                          ) : null}
                        </tr>
                      )
                    })
          }
          theme={props.theme}
          maxHeight='16rem'
          style={{minHeight: '8rem'}}
        ></Table>
      </div>
    </div>
  )

  if (isSettingsLoading) {
    props.storePayload({
      isLoading: true,
    })
    return <></>
  } else {
    props.storePayload({
      isLoading: false,
    })
    return (
      <div id='settings-page'>
        {isResetAuthKeyModal && (
          <>
            <Modal
              content={resetAuthKeyContent}
              confirmText='Confirm'
              cancelText='Cancel'
              onConfirm={onConfirmResetAuthKey}
              onCancel={onCancelResetAuthKey}
              showClose={false}
              isVisible={isResetAuthKeyModalShowing}
              theme={props.theme}
              showCancel={true}
              outsideClickDismisses={true}
            />
          </>
        )}
        <div className='cards'>
          {(props.usersObj?.[props.user.uid]?.role === 'admin' || permissions?.manageBilling) &&
          <MetricCard
            title='Manage Billing'
            icon={<Icon icon='external-link-outline' />}
            value={
              <a
                href={props.customerPortal || null}
                id='customer-portal'
                className='cursor-pointer'
                target='_blank'
                rel='noopener noreferrer'
                style={{fontSize: '0.9em'}}
                onClick={() => {
                  if (!props.customerPortal && !isSettingsLoading) {
                    const anchorElem = document.getElementById('customer-portal')
                    setIsSettingsLoading(true)
                    props.getCustomerPortal(
                        props.orgId,
                        props.user.uid,
                        props.user.email,
                        setIsSettingsLoading,
                        anchorElem,
                    )
                  }
                }}
              >
                Open Portal
              </a>
            }
          />
          }
          {/* <MetricCard
            title='Free Credits'
            icon={<Icon icon='credit-card-outline' />}
            value={props.billing?.promo?.amount ? '$' + props.billing?.promo?.amount : 'N/A'}
            color='metallic-blue'
          /> */}
          {(props.usersObj?.[props.user.uid]?.role === 'admin' || permissions?.resetAuthKey) &&
            <MetricCard
              title='Reset Auth Key'
              icon={<Icon icon='info-outline' />}
              color='french-puce'
              value={
                <a
                  href={null}
                  className='cursor-pointer'
                  onClick={onClickResetAuthKey}
                  style={{fontSize: '0.9em'}}
                >
                  Reset Key
                </a>
              }
            />
          }
        </div>
        <PermissionSideTab
          email={permissionsEmail}
          headerText={(permissionsID === inviteEmail && inviteEmail.length > 0) ? 'Invite User': 'User Permissions'}
          id={permissionsID}
          permissionUser={permissionsUser}
          isVisible={permissionsModalShowing}
          onClose={() => {
            setPermissionsModalShowing(false)
            setPermissionsUser(null)
          }}
          onSave={savePermissions}
          shouldShowVKG={props.vkgDomain}
        />
        <TabContainer
          theme={props.theme}
          className='tabs'
          activeTab={activeTab}
          canChangeTab={handleProfileChanges}
          onTabChange={(tabName) => {
            setActiveTab(tabName)
          }}
          tabContent={{
            profile: {
              render: <Profile />,
              isDisabled: false,
              title: 'Profile',
            },
            organization: {
              render: organizationTab,
              isDisabled: !canAccessOrganization,
              title: 'Organization',
            },
            roles: {
              render: <Roles/>,
              isDisabled: !canAccessOrganization,
              title: 'Roles',
            },
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  cookies: ownProps.cookies,
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  usersObj: state.userReducer.usersObj,
  users: state.userReducer.users,
  userData: state.userReducer.userData,
  user: state.userReducer.user,
  orgDetails: state.userReducer.orgDetails,
  orgId: state.userReducer.orgId,
  subOrgs: state.userReducer.subOrgs,
  orgActionLogs: state.userReducer.orgActionLogs,
  customerPortal: state.userReducer.customerPortal,
  billing: state.userReducer.billing,
  theme: state.userReducer.theme,
  analytics: state.firebaseReducer.analytics,
  profileChangesMade: state.userReducer.profileChangesMade,
  preventNavigationListener: state.userReducer.preventNavigationListener,
  vkgDomain: state.userReducer.vkgDomain,
  authKey: state.userReducer.authKey,
  customRoles: state.userReducer.customRoles,
})

export default connect(mapStateToProps, {
  getUsers,
  getSubOrgs,
  getOrgActionLogs,
  getCustomerPortal,
  storePayload,
  logAnalyticsEvent,
  // getBilling,
})(Settings)
