import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
// import codingLanguageList from '../CodingLanguages.js'
// import firebase from 'firebase/compat/app'
import defaultPerms from '../defaultPerms.json'
import UserTable from './UserTable'
import AddUsersSideTab from './AddUsersSideTab.jsx'
import Helpers from '../Helpers.js'
import 'firebase/compat/auth'
import {
  Icon,
  Table,
  TableEntry,
  TabContainer,
  Button,
  Input,
  Card,
  // CollapseCard,
  Switch,
  Select,
} from '@lazarusai/forms-ui-components'
import {storePayload} from '../actions/storePayload.js'
import {enterMFACode} from '../actions/enterMFACode'
import {setDefaultLanguage} from '../actions/setDefaultLanguage.js'
import {setNames} from '../actions/setNames.js'
import {removeAnalytics} from '../actions/removeAnalytics.js'
import {initializeAnalytics} from '../actions/initializeAnalytics.js'
import endpoints from '../endpoints.json'

import '../styles/Roles.css'
import {deleteCustomRole} from '../actions/deleteCustomRole'
import {setCustomRole} from '../actions/setCustomRole'

function Roles(props) {
  const [roleSelection, setRoleSelection] = useState('admin')
  const [changeTab, setChangeTab] = useState(false)
  const [roleTabName, setRoleTabName] = useState('general')
  const [userRolesTableEntry, setUserRolesTableEntry] = useState({'admin': null, 'intermediate': null, 'basic': null, 'custom': null})
  const [isVisible, setIsVisible] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isCustomRole, setIsCustomRole] = useState(false);
  const [interRole, setInterRole] = useState({})
  const [editedRoleName, setEditedRoleName] = useState('')
  const [areThereUnsavedChanges, setAreThereUnsavedChanges] = useState(false)
  const [hasFullVKGAccess, setHasFullVKGAccess] = useState(false)
  const [selectedVKGs, setSelectedVKGs] = useState([])
  const [vkgSearchString, setVKGSearchString] = useState('')
  const handleProfileChanges = () => {
    if (props.profileChangesMade) {
      if (window.confirm('You have unsaved changes. Are you sure you want to continue?')) {
        props.storePayload({
          profileChangesMade: false,
        })
        window.removeEventListener('beforeunload', props.preventNavigationListener)
      } else {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    if (!roleSelection || Object.keys(props.customRoles || {}).includes(roleSelection)) {
      const interVKG = Helpers.encodeVKGAccess(generateVKGAccess())
      if (editedRoleName !== roleSelection) {
        if (editedRoleName?.length && !['admin', 'intermediate', 'basic'].includes(editedRoleName.toLowerCase())) {
          setAreThereUnsavedChanges(true)
        } else {
          setAreThereUnsavedChanges(false)
        }
      } else if (
        roleSelection &&
        Object.keys(interRole['permissions'] || {}).some((permKey) => interRole['permissions'][permKey] !== props.customRoles?.[roleSelection]?.['permissions']?.[permKey])
      ) {
        setAreThereUnsavedChanges(true)
      } else if (
        roleSelection &&
        props.vkgDomain &&
        (Object.keys(interVKG || {}).some((vkgKey) => interVKG?.[vkgKey] !== props.customRoles?.[roleSelection]?.['vkgAccess']?.[vkgKey]) ||
        Object.keys(interVKG || {}).length !== Object.keys(props.customRoles?.[roleSelection]?.['vkgAccess'] || {}).length)
      ) {
        setAreThereUnsavedChanges(true)
      } else {
        setAreThereUnsavedChanges(false)
      }
    }
  }, [editedRoleName, interRole, selectedVKGs, hasFullVKGAccess, props.customRoles])

  function initializeRoleState() {
    setEditedRoleName(roleSelection || '')
    if (Object.keys(props.customRoles || {}).includes(roleSelection)) {
      setInterRole(props.customRoles[roleSelection])
      const vkgsFull = Object.keys(Helpers.decodeVKGAccess(props.customRoles[roleSelection]?.['vkgAccess'] || {}))
      setSelectedVKGs(vkgsFull.filter((vkgKey) => vkgKey !== 'vkgs'))
      setHasFullVKGAccess(['read', 'write'].some((perm) => props.customRoles[roleSelection]?.['vkgAccess']?.vkgs?.includes(perm)))
    } else if (roleSelection) { // defaults
      setEditedRoleName(capitalize(roleSelection))
      setInterRole({
        'permissions': {
          'manageBilling': defaultPerms['manageBilling']['roles'].includes(roleSelection),
          'inviteUser': defaultPerms['inviteUser']['roles'].includes(roleSelection),
          'readVKG': defaultPerms['readVKG']['roles'].includes(roleSelection),
          'resetAuthKey': defaultPerms['resetAuthKey']['roles'].includes(roleSelection),
          'setPermissions': defaultPerms['setPermissions']['roles'].includes(roleSelection),
          'useDemoCredits': defaultPerms['useDemoCredits']['roles'].includes(roleSelection),
          'viewAuditLogs': defaultPerms['viewAuditLogs']['roles'].includes(roleSelection),
          'viewCredentials': defaultPerms['viewCredentials']['roles'].includes(roleSelection),
          'viewLogData': defaultPerms['viewLogData']['roles'].includes(roleSelection),
          'viewLogs': defaultPerms['viewLogs']['roles'].includes(roleSelection),
          'writeVKG': defaultPerms['writeVKG']['roles'].includes(roleSelection),
          'controlSubOrgs': defaultPerms['controlSubOrgs']['roles'].includes(roleSelection),
        },
      })
    } else {
      setInterRole({
        'permissions': {
          'manageBilling': false,
          'inviteUser': false,
          'readVKG': false,
          'resetAuthKey': false,
          'setPermissions': false,
          'useDemoCredits': false,
          'viewAuditLogs': false,
          'viewCredentials': false,
          'viewLogData': false,
          'viewLogs': false,
          'writeVKG': false,
          'controlSubOrgs': false,
        },
        'vkgAccess': {},
      })
    }
  }

  useEffect(() => {
    initializeRoleState()
  }, [roleSelection])

  const permissionStructure = {
    'Billing and Credits': ['manageBilling', 'useDemoCredits'],
    'Admin': ['setPermissions', 'inviteUser', 'controlSubOrgs', 'resetAuthKey', 'viewCredentials'],
    'Log Data': ['viewLogs', 'viewAuditLogs', 'viewLogData'],
    'Vector Knowledge Graphs': ['readVKG', 'writeVKG'],
  }

  const permissionsInfo = {
    'manageBilling': {
      'name': 'View/manage billing',
      'role': 'admin',
    },
    'useDemoCredits': {
      'name': 'Use demo credit',
      'role': 'intermediate',
    },
    'setPermissions': {
      'name': 'Set user permissions',
      'role': 'admin',
    },
    'inviteUser': {
      'name': 'Invite new users',
      'role': 'admin',
    },
    'resetAuthKey': {
      'name': 'Reset auth key',
      'role': 'admin',
    },
    'controlSubOrgs': {
      'name': 'Control sub orgs',
      'role': 'admin',
    },
    'viewCredentials': {
      'name': 'View credentials',
      'role': 'intermediate',
    },
    'viewLogs': {
      'name': 'View basic log data',
      'role': 'basic',
    },
    'viewAuditLogs': {
      'name': 'View audit log data',
      'role': 'admin',
    },
    'viewLogData': {
      'name': 'Download log data',
      'role': 'intermediate',
    },
    'readVKG': {
      'name': 'Read VKGs',
      'role': 'basic',
      'description': 'Get graphs and nodes, search VKG, use RikYChat, and export VKGs.',
    },
    'writeVKG': {
      'name': 'Write VKGs',
      'role': 'admin',
      'description': 'Create VKGs, add, edit, and delete nodes, delete VKGs, compute TSNE, and duplicate VKGs.',
    },
  }

  function getPermissionStructure(roleSelection) {
    const interPerms = structuredClone(permissionStructure)
    if (!props.vkgDomain) {
      delete interPerms['Vector Knowledge Graphs']
    }
    if (!props.orgDetails?.permissions?.canCreateSubOrg) {
      interPerms['Admin'] = structuredClone(interPerms['Admin'].filter((perm) => perm !== 'controlSubOrgs'))
    }
    return interPerms
  }

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    const userData = {'admin': 0, 'intermediate': 0, 'basic': 0, 'custom': 0}
    // Update userRoles state
    Object.keys(props.usersObj).forEach((key) => {
      const role = props.usersObj[key].role;
      if (Object.keys(userData).includes(role)) {
        userData[role] = userData[role] + 1
      } else if (role) {
        userData[role] = 1
      }
    })
    // Update userRolesTableEntry state
    const updatedTableEntry = {};
    Object.keys(userData).forEach((key) => {
      const tableEntry = `${userData[key]} users`
      updatedTableEntry[key] = tableEntry;
    });
    setUserRolesTableEntry(updatedTableEntry);
  }, [props.usersObj]);

  const handleSave = (selectedUsers) => {
    // const permissions = props.usersObj[props.user.uid]['permissions']
    for (const id of selectedUsers) {
      changeUserPermissions(
          props.customRoles?.[roleSelection]?.permissions,
          id,
          roleSelection,
          props.customRoles?.[roleSelection]?.vkgAccess,
      );
    }
    setSelectedUsers([])
  };

  function changeUserPermissions(permissions, id, currentRole, vkgAccess) {
    const newUsersObj = props.usersObj
    newUsersObj[id].role = currentRole
    props.storePayload({usersObj: newUsersObj})
    // const url = process.env.REACT_APP_URL + 'update/user'
    const url = process.env.REACT_APP_URL + endpoints.updateUser
    const data = {
      userId: id,
      orgId: props.orgId,
      permissions: permissions,
      vkgAccess: Helpers.encodeVKGAccess(vkgAccess),
      role: currentRole,
    }
    const headers = {
      'orgId': props.orgId,
      'userId': props.user.uid,
      'authKey': props.authKey,
    }
    return Helpers.fetchPostJsonWithStatus(url, data, headers)
  }

  const sidebar = (
    <AddUsersSideTab
      isVisible={isVisible}
      currentUsers={selectedUsers.map((userEmail) => Object.keys(props.usersObj)[listOfNames().findIndex((x) => x === userEmail)])}
      onClose={() => setIsVisible(false)}
      onSave={handleSave} // Use an arrow function to pass the reference
    />
  );

  const rolesTab = (
    <div
      name='Role'
      id='roles-page'
      className='show-scrollbar'
    >
      <div className='role-title-header'>
        <h1>Roles</h1>
        <p> User roles help you set up permissions in a consistent manner.</p>
      </div>
      <Table
        theme={props.theme}
        title='Default Roles'
        content='Default roles come with set access and permissions. Default settings cannot be changed.'
        style={{minHeight: 'auto'}}
        columnTitles={['Role', 'Users']}
        showCount={false}
        className={'roles-table'}
        entries={[
          <TableEntry
            key={'admin-role'}
            className='roles-table-entry'
            content={[
              <div key='entry' className='description'>
                <h4>Admin</h4>
              </div>,
              <div key='entry2' className='user-button'>
                {userRolesTableEntry['admin']}
                <Button
                  onClick={() => {
                    setChangeTab(true)
                    setRoleSelection('admin')
                    setIsCustomRole(false)
                  }}
                  style={{marginLeft: 'auto'}}
                  buttonType='submit'
                  text='View Details'
                  theme={props.theme}
                  width='50%'
                />
              </div>,
            ]}
          />,
          <TableEntry
            key={'intermediate-role'}
            className='roles-table-entry'
            content={[
              <div key='entry' className='description'>
                <h4>Intermediate</h4>
              </div>,
              <div key='entry2' className='user-button'>
                {userRolesTableEntry['intermediate']}
                <Button
                  onClick={() => {
                    setChangeTab(true)
                    setRoleSelection('intermediate')
                    setIsCustomRole(false)
                  }}
                  style={{marginLeft: 'auto'}}
                  buttonType='submit'
                  text='View Details'
                  theme={props.theme}
                  width='50%'
                />
              </div>,
            ]}
          />,
          <TableEntry
            key={'basic-entry'}
            className='roles-table-entry'
            content={[
              <div key='entry' className='description'>
                <h4>Basic</h4>
              </div>,
              <div key='entry2' className='user-button'>
                {userRolesTableEntry['basic']}
                <Button
                  onClick={() => {
                    setChangeTab(true)
                    setRoleSelection('basic')
                    setIsCustomRole(false)
                  }}
                  style={{marginLeft: 'auto'}}
                  buttonType='submit'
                  text='View Details'
                  theme={props.theme}
                  width='50%'
                />
              </div>,
            ]}
          />,
          <TableEntry
            key={'custom-role'}
            className='roles-table-entry'
            content={[
              <div key='entry' className='description'>
                <h4><i>Custom</i></h4>
              </div>,
              <div key='entry2' className='user-button'>
                {userRolesTableEntry['custom']}
                <Button
                  style={{marginLeft: 'auto'}}
                  buttonType='submit'
                  text='View Details'
                  theme={props.theme}
                  width='50%'
                  disabled={true}
                />
              </div>,
            ]}
          />,
        ]}
      />
      <Table
        theme={props.theme}
        title={
          <div
            className={'custom-role-header'}
          >
            <div
              className={'role-header-text'}
            >
              Custom Roles
            </div>
            <Button
              onClick={() => {
                setChangeTab(true)
                setRoleSelection(null)
                setIsCustomRole(true)
              }}
              style={{marginLeft: 'auto'}}
              buttonType='submit'
              text='Create Custom Role'
              theme={props.theme}
              width='25%'
            />
          </div>
        }
        style={{minHeight: 'auto'}}
        columnTitles={['Role', 'Users']}
        showCount={false}
        className={'roles-table'}
        entries={
          Object.keys(props.customRoles || {}).map((roleName) => {
            return (
              <TableEntry
                key={`${roleName}-table-entry`}
                className='roles-table-entry'
                content={[
                  <div key='entry' className='description'>
                    <h4>{roleName}</h4>
                  </div>,
                  <div key='entry2' className='user-button'>
                    {userRolesTableEntry[roleName] || '0 users'}
                    <Button
                      onClick={() => {
                        setChangeTab(true)
                        setRoleSelection(roleName)
                        setIsCustomRole(true)
                      }}
                      style={{marginLeft: 'auto'}}
                      buttonType='submit'
                      text='View/Edit Details'
                      theme={props.theme}
                      width='50%'
                    />
                  </div>,
                ]}
              />
            )
          })}
      />
      <div></div>
    </div>
  )
  const breadcrumb = (cb) => {
    return (
      <div
        className='back-roles bright-green hover-link'
        onClick={cb}
      >
        <Icon
          icon={'arrow-back-outline'}
        />
        <div
          className='back-roles-text'
        >
        Back to Roles
        </div>
      </div>
    )
  }

  function listOfNames(excludeMe=false) {
    const namesList = [];
    Object.keys(props.usersObj).forEach((key) => {
      if (excludeMe && key === props.user.uid) {
        return
      }
      namesList.push(props.usersObj[key].email);
    });
    return namesList;
  };

  const assignedUsersTab = (
    <>
      {(roleSelection !== 'custom') &&
      <Card
        title='Add User'
        theme={props.theme}
      >
        {/* make this a multi-select */}
        <Select
          value={selectedUsers}
          style={{width: '20%'}}
          onClickOption={(optionName) => {
            if (selectedUsers.includes(optionName)) {
              setSelectedUsers(selectedUsers.filter((name) => name !== optionName))
            } else {
              setSelectedUsers([...selectedUsers, optionName])
            }
          }}
          options={listOfNames(true)}
          theme={props.theme}
          placeholder='Choose a User'
          isMultiselect={true}
        />
        <Button
          style={{marginLeft: 'auto', marginTop: '0.75rem'}}
          buttonType='submit'
          text='Add User'
          theme={props.theme}
          width='25%'
          onClick={() => setIsVisible(true)}
        />
      </Card>
      }
      <UserTable roleSelection={roleSelection}></UserTable>
    </>
  )
  const generalTab = (
    <div id='general-tab'>
      <Card
        title={capitalize(roleSelection || 'New Custom Role')}
        content='Last updated: 2024-01-06'
        theme={props.theme}
      >
        <Input
          label='Role: '
          placeholder={'Role Name'}
          width='50%'
          value={editedRoleName}
          theme={props.theme}
          type={1}
          disabled={!isCustomRole}
          onChange={(e) => {
            setEditedRoleName(Helpers.removeSpecialCharacters(e.target.value)) // no non-firebase friendly chars
          }}
        />
        <div className='role-input-wrapper'>
          {roleSelection !== 'custom' && (
            <>
              <h4>Permissions</h4>
              <p>Default permissions for this group.</p>
              {Object.keys(getPermissionStructure(roleSelection)).map((permissionGroupKey) => {
                const individualPerms = getPermissionStructure(roleSelection)[permissionGroupKey]
                return (
                  <>
                    <h4 className='perm-title'>{permissionGroupKey}</h4>
                    {individualPerms.map((permKey) => {
                      const permName = permissionsInfo?.[permKey]?.name
                      const permDesc = permissionsInfo?.[permKey]?.description
                      return (
                        <div
                          className='perm-switch-row'
                          key={`${permKey}-row`}
                        >
                          <div className='perm-switch-text'>
                            {permName}
                            {permDesc && <p>{permDesc}</p>}
                          </div>
                          <div className={`toggle-right-align ${!isCustomRole ? 'disabled-switch': ''}`}>
                            <Switch
                              theme={props.theme}
                              checked={interRole?.['permissions']?.[permKey]}
                              disabled={!isCustomRole}
                              onChange={(e) => {
                                const copyPerm = structuredClone(interRole)
                                copyPerm['permissions'][permKey] = e.target.checked
                                if (permKey == 'writeVKG' && e.target.checked) {
                                  copyPerm['permissions']['readVKG'] = true
                                }
                                setInterRole(copyPerm)
                              }}
                              key={`${permKey}-${isCustomRole}`}
                            />
                          </div>
                        </div>
                      )
                    })}
                  </>
                )
              })}
              {isCustomRole && props.vkgDomain &&
        <>
          <div className='permission-sub-header'>
            Access
          </div>
          <div
            className='permission-collapse-body-row permission-side-section'
          >
            <div
              className={`permission-collapse-body-row-text-section ${(['read', 'write'].every((perm) => props.userData?.vkgAccess?.vkgs?.includes(perm)))}`}
            >
              <div className='permission-collapse-body-row-text-main'>
              Full VKG Server Access
              </div>
              <div className='permission-collapse-body-row-text-secondary'>
              Access to all VKGs within an organization instance.
              </div>
            </div>
            <div
              className='permission-collapse-body-row-switch'
              onClick={() => {
                setHasFullVKGAccess(true)
              }}
            >
              <Icon
                key={hasFullVKGAccess ? 'radio-button-on-full': 'radio-button-off-full'}
                icon={hasFullVKGAccess ? 'radio-button-on': 'radio-button-off'}
                className={hasFullVKGAccess ? 'permission-active-icon': ''}
              />
            </div>
          </div>
          <div
            className='permission-collapse-body-row permission-side-section'
          >
            <div
              className='permission-collapse-body-row-text-section'
            >
              <div className='permission-collapse-body-row-text-main'>
              Graph-Specific Access Only
              </div>
              <div className='permission-collapse-body-row-text-secondary'>
              Access to only the VKGs specified
              </div>
            </div>
            <div
              className='permission-collapse-body-row-switch'
              onClick={() => {
                setHasFullVKGAccess(false)
              }}
            >
              <Icon
                key={hasFullVKGAccess ? 'radio-button-on': 'radio-button-off'}
                icon={!hasFullVKGAccess ? 'radio-button-on': 'radio-button-off'}
                className={!hasFullVKGAccess ? 'permission-active-icon': ''}
              />
            </div>
          </div>
          {/* VKG Select */}
          {!hasFullVKGAccess && <>
            <div className='permission-side-section permission-select-vkgs-header'>
              Select VKGs
            </div>
            <Input
              theme={props.theme}
              value={vkgSearchString}
              onChange={(e) => {
                setVKGSearchString(e.target.value)
              }}
              iconLeft={<Icon icon={'search-outline'} />}
              placeholder={'Search'}
              className={'permission-side-section'}
            />
            <div
              className='permission-select-vkgs-info permission-side-section'
            >
              <div
                className='permission-select-vkgs-info-count'
              >
                {`${selectedVKGs.length} VKGs selected`}
              </div>
              <div
                className='permission-select-vkgs-info-options'
              >
                <span
                  className='option-text'
                  onClick={() => {
                    setSelectedVKGs(Object.keys(props.graphObj))
                  }}
                >{`Select All  `}</span>
                <span className='dot-icon'>&bull;</span>
                <span
                  className='option-text'
                  onClick={() => {
                    setSelectedVKGs([])
                  }}
                >{`  Clear`}</span>
              </div>
            </div>
            <div
              className='permission-vkg-lists show-scrollbar'
            >
              {Object.keys(props.graphObj || {})
                  .sort()
                  .sort((aId, bId) => {
                    const aValueInter = aId.toLowerCase().indexOf(vkgSearchString.toLowerCase())
                    const bValueInter = bId.toLowerCase().indexOf(vkgSearchString.toLowerCase())
                    const aValue = aValueInter > -1 ? aValueInter : 1000
                    const bValue = bValueInter > -1 ? bValueInter : 1000
                    return aValue - bValue
                  })
                  .map(
                      (vkgId) => {
                        return (
                          <div
                            key={`vkg-select-${vkgId}`}
                            className='permission-side-section permission-vkg-select-row'
                          >
                            <div
                              className='permission-vkg-select-check'
                            >
                              <Switch
                                type='checkbox'
                                checked={selectedVKGs.includes(vkgId)}
                                onChange={(e) => {
                                  if (selectedVKGs.includes(vkgId)) {
                                    setSelectedVKGs([...selectedVKGs.filter((graphId) => graphId !== vkgId)])
                                  } else {
                                    setSelectedVKGs([...selectedVKGs, vkgId])
                                  }
                                }}
                                theme={props.theme}
                              />
                            </div>
                            <div
                              className='permission-vkg-select-text'
                            >
                              <div
                                className='permission-vkg-select-name'
                              >
                                {vkgId}
                              </div>
                              <div
                                className='permission-vkg-select-nodes'
                              >
                                {`${props.graphObj[vkgId]?.nodes} nodes`}
                              </div>
                            </div>
                          </div>
                        )
                      })
              }
            </div>
          </>
          }
        </>}
            </>
          )}
        </div>
      </Card>
    </div>
  )
  const manageRolesTab = (
    <div
      name='Manage Roles'
      className='tab-content show-scrollbar'
    >
      <div>
        <br></br>
        {breadcrumb(
            () => {
              setChangeTab(false)
            },
        )}
      </div>
      <div style={{padding: '12px'}}>
        <h1>Manage Role</h1>
        <p>User roles help you set up permissions in a consistent manner.</p>
      </div>
      <div
        className='role-input-delete-row'
      >
        <Input
          onChange={(e) => {
            if (Object.keys(props.customRoles || {}).includes(e.target.value)) {
              setIsCustomRole(true)
              setRoleSelection(e.target.value)
            } else {
              setIsCustomRole(false)
              setRoleSelection(e.target.value.toLowerCase())
            }
          }}
          label='Manage Role: '
          placeholder='New Role'
          width='100%'
          value={
          Object.keys(props.customRoles || {}).includes(roleSelection) ?
            roleSelection :
            capitalize(roleSelection || '')
          }
          theme={props.theme}
          required
          type={3}
          options={['Admin', 'Intermediate', 'Basic', ...Object.keys(props.customRoles || {})]}
          iconLeft={<Icon icon='menu-outline' className='profile-left-icon'/>}
        />
        {isCustomRole && roleSelection && <Button
          text={`Delete ${roleSelection}`}
          theme={props.theme}
          type={10}
          onClick={() => {
            const checkDelete = confirm(`Are you sure you want to delete ${roleSelection}`)
            if (checkDelete) {
              props.deleteCustomRole(props.database, roleSelection)
              setChangeTab(false)
            }
          }}
        />}
      </div>

      <TabContainer
        theme={props.theme}
        className='role-tabs'
        activeTab={roleTabName}
        canChangeTab={handleProfileChanges}
        style={{minHeight: '50rem'}}
        onTabChange={(tabName) => {
          setRoleTabName(tabName)
        }}
        tabContent={{
          general: {
            render: generalTab,
            isDisabled: false,
            title: 'General',
          },
          assignedUsers: {
            render: assignedUsersTab,
            isDisabled: false,
            title: 'Assigned Users',
          },
        }}
      />
      {areThereUnsavedChanges && <div className={`buttons-lower buttons-lower-${areThereUnsavedChanges ? 'in' : 'out'}`}>
        <Button
          type='8'
          text='Save'
          theme={props.theme}
          onClick={saveChanges}
          icon={<Icon icon='arrow-forward-outline' />}
          iconPosition='right'
          iconJustify='edge'
        />
        <Button
          type='10'
          text='Revert Changes'
          onClick={revertChanges}
          theme={props.theme}
          icon={<Icon icon='alert-circle-outline' />}
          iconPosition='right'
          iconJustify='edge'
        />
      </div>}
    </div>
  )

  async function saveChanges() {
    if (isCustomRole) {
      if (roleSelection && editedRoleName !== roleSelection) {
        props.deleteCustomRole(props.database, roleSelection)
      }
      const roleBody = structuredClone(interRole)
      roleBody['vkgAccess'] = Helpers.encodeVKGAccess(generateVKGAccess())
      const success = await props.setCustomRole(props.database, editedRoleName, roleBody)
      if (success) {
        setRoleSelection(editedRoleName)
      }
    }
  }

  function doesUserHaveFullAccess() { // check if signed in user has full access
    const allVKGsAccess = props.usersObj?.[props.user.uid]?.vkgAccess?.vkgs || ''
    return ['read', 'write', 'create'].every((perm) => allVKGsAccess.includes(perm))
  }

  function generateVKGAccess() {
    if (hasFullVKGAccess && doesUserHaveFullAccess()) {
      if (interRole?.permissions?.writeVKG) {
        return {vkgs: 'read,create,write'}
      } else if (interRole?.permissions?.readVKG) {
        return {vkgs: 'read'}
      } else {
        return {}
      }
    } else {
      const retVKGAccess = {}
      if (interRole?.permissions?.writeVKG) {
        retVKGAccess['vkgs'] = 'create'
      }
      const accessString = `${(interRole?.permissions?.readVKG || interRole?.permissions?.writeVKG) ? 'read': ''}${interRole?.permissions?.writeVKG ? ',write': ''}`
      if (hasFullVKGAccess) {
        const allVKGs = Object.keys(props.graphObj || {})
        for (let sIndex = 0; sIndex < allVKGs.length; sIndex++) {
          retVKGAccess[allVKGs[sIndex]] = accessString
        }
      } else {
        for (let sIndex = 0; sIndex < selectedVKGs.length; sIndex++) {
          retVKGAccess[selectedVKGs[sIndex]] = accessString
        }
      }
      return retVKGAccess
    }
  }

  function revertChanges() {
    initializeRoleState()
  }

  return (
    <div
      className='role-wrapper'
    >
      {!changeTab ? rolesTab : manageRolesTab }
      {sidebar}
    </div>
  )
}

const mapStateToProps = (state) => ({
  database: state.firebaseReducer.database,
  user: state.userReducer.user,
  orgId: state.userReducer.orgId,
  orgDetails: state.userReducer.orgDetails,
  userData: state.userReducer.userData,
  theme: state.userReducer.theme,
  isSaveCred: state.userReducer.isSaveCred,
  profileChangesMade: state.userReducer.profileChangesMade,
  usersObj: state.userReducer.usersObj,
  authKey: state.userReducer.authKey,
  customRoles: state.userReducer.customRoles,
  vkgDomain: state.userReducer.vkgDomain,
  orgDetails: state.userReducer.orgDetails,
  graphObj: state.userReducer.graphObj,
})

export default connect(mapStateToProps, {
  storePayload,
  setDefaultLanguage,
  setNames,
  removeAnalytics,
  initializeAnalytics,
  enterMFACode,
  deleteCustomRole,
  setCustomRole,
})(Roles)
