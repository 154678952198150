import React, {useState, useEffect, useRef} from 'react'
import {Navigate} from 'react-router-dom'
import Helpers from '../Helpers.js'
import {connect} from 'react-redux'
import {storePayload} from '../actions/storePayload.js'
import {logAnalyticsEvent} from '../actions/logAnalyticsEvent'
// import ApiUsageChart from './ApiUsageChart.jsx'
import Logs from './Logs'
import '../styles/Api.css'
import {Icon, Card} from '@lazarusai/forms-ui-components'
import ModularMetrics from './ModularMetrics.jsx'

function Api(props) {
  const [isEndpointCopied, setIsEndpointCopied] = useState(false)
  const [isOrgIdCopied, setIsOrgIdCopied] = useState(false)
  const [isAuthKeyCopied, setIsAuthKeyCopied] = useState(false)

  const [isOrgIdShowing, setIsOrgIdShowing] = useState(false)
  const [isAuthKeyShowing, setIsAuthKeyShowing] = useState(false)
  const endpoint = `https://${process.env.REACT_APP_URL_PARAM}.lazarusforms.com/api/{...}`
  const scrollRef = useRef()
  const hiddenString = '*************'

  const onClickCopyEndpoint = () => {
    Helpers.copyToClipBoard(endpoint, () => {
      setIsEndpointCopied(true)
    })

    setTimeout(() => {
      setIsEndpointCopied(false)
    }, 2000)
  }

  const onClickCopyOrgId = () => {
    Helpers.copyToClipBoard(props.orgId, () => {
      setIsOrgIdCopied(true)
    })

    setTimeout(() => {
      setIsOrgIdCopied(false)
    }, 2000)
  }

  const onClickCopyAuthKey = () => {
    Helpers.copyToClipBoard(props.authKey, () => {
      setIsAuthKeyCopied(true)
    })

    setTimeout(() => {
      setIsAuthKeyCopied(false)
    }, 2000)
  }

  const apiPageDependencies = [props.orgId, props.authKey, props.usersObj]

  const logs =
      props.logs && props.logs.length ?
        props.logs :
        []

  const apiPermissions = [
    'viewLogs',
    'viewCredentials',
  ]

  const permissions = props.usersObj?.[props.user.uid]?.permissions
  const canAccessPage =
    props.usersObj?.[props.user.uid]?.role === 'admin' || (permissions && apiPermissions.every((permission) => {
      return permissions[permission]
    }))


  useEffect(() => {
    if (props.user) {
      props.logAnalyticsEvent('get_api_data', {
        user_id: props.user.uid,
        hipaa_mode_on: props.orgDetails && props.orgDetails['hipaaMode'] ? props.orgDetails['hipaaMode'] : false,
        page: window.location.pathname.substring(1),
        time: Date.now(),
        user: window.navigator.userAgent,
        event_type: 'api',
      })
    }
  }, [props.user])

  function setOrgIdShowing(e) {
    e.preventDefault()
    if (!isOrgIdShowing) {
      props.logAnalyticsEvent('viewed_org_id', {
        user_id: props.user.uid,
        page: window.location.pathname.substring(1),
        time: Date.now(),
        user: window.navigator.userAgent,
        event_type: 'click',
      })
    }
    setIsOrgIdShowing(!isOrgIdShowing)
  }

  function setAuthKeyShowing(e) {
    e.preventDefault()
    if (!isAuthKeyShowing) {
      props.logAnalyticsEvent('viewed_auth_key', {
        user_id: props.user.uid,
        page: window.location.pathname.substring(1),
        time: Date.now(),
        user: window.navigator.userAgent,
        event_type: 'click',
      })
    }
    setIsAuthKeyShowing(!isAuthKeyShowing)
  }

  if (!apiPageDependencies.every((a) => a)) {
    props.storePayload({
      isLoading: true,
    })
    return <></>
  } else if (!canAccessPage) {
    props.storePayload({
      isLoading: false,
    })
    return <Navigate to='/'/>
  } else {
    props.storePayload({
      isLoading: false,
    })
    return (
      <div id='api-page' className='show-scrollbar'>
        <Card
          title='Endpoint'
          theme={props.theme}
          className='background-metallic-blue'
        >
          <p className='card-title text-bright-green'>{endpoint}</p>
          <div className='flex cursor-pointer' onClick={onClickCopyEndpoint}>
            <div
              className={`toggle-container ${isEndpointCopied ? 'toggled' : ''}`}
            >
              <Icon icon='checkmark-outline' />
              <Icon icon='copy-outline' />
            </div>
            <span>{isEndpointCopied ? 'Copied' : 'Copy to Clipboard'}</span>
          </div>
        </Card>
        <div className='orgId-authkey-container'>

          <Card title='Org Id' theme={props.theme} className='background-salem'>
            <p className='card-title text-bright-green'>
              {isOrgIdShowing ? props.orgId : hiddenString}
            </p>
            <div data-test-id='orgId-container' className='card-copy-section gap-group gap-group-columns gap-group-columns-auto justify-start'>
              <div
                className='flex cursor-pointer'
                onClick={setOrgIdShowing}
              >
                <div
                  className={`toggle-container ${!isOrgIdShowing ? 'toggled' : ''}`}
                >
                  <Icon icon='eye-outline' />
                  <Icon icon='eye-off-2-outline' />
                </div>
                <span>{isOrgIdShowing ? 'Hide' : 'Show'}</span>
              </div>
              {isOrgIdShowing &&
                <div className='flex cursor-pointer' onClick={onClickCopyOrgId}>
                  <div
                    className={`toggle-container ${isOrgIdCopied ? 'toggled' : ''}`}
                  >
                    <Icon icon='checkmark-outline' />
                    <Icon icon='copy-outline' />
                  </div>
                  <span>{isOrgIdCopied ? 'Copied' : 'Copy to Clipboard'}</span>
                </div>
              }
            </div>
          </Card>
          <Card title='Auth Key' theme={props.theme} className='background-salem'>
            <p className='card-title text-bright-green'>
              {isAuthKeyShowing ? props.authKey : hiddenString}
            </p>
            <div data-test-id='authKey-container' className='card-copy-section gap-group gap-group-columns gap-group-columns-auto justify-start'>
              <div
                className='flex cursor-pointer'
                onClick={setAuthKeyShowing}
              >
                <div
                  className={`toggle-container ${
                    !isAuthKeyShowing ? 'toggled' : ''
                  }`}
                >
                  <Icon icon='eye-outline' />
                  <Icon icon='eye-off-2-outline' />
                </div>
                <span>{isAuthKeyShowing ? 'Hide' : 'Show'}</span>
              </div>
              {isAuthKeyShowing &&
                <div className='flex cursor-pointer' onClick={onClickCopyAuthKey}>
                  <div
                    className={`toggle-container ${isAuthKeyCopied? 'toggled' : ''}`}
                  >
                    <Icon icon='checkmark-outline' />
                    <Icon icon='copy-outline' />
                  </div>
                  <span>{isAuthKeyCopied ? 'Copied' : 'Copy to Clipboard'}</span>
                </div>
              }
            </div>
          </Card>
        </div>
        {/* {props.lineChartData && props.lineChartData.length ? (
          <ApiUsageChart
            data={props.lineChartData}
            scrollRef={scrollRef}
            xAxis={{
              type: 'datetime',
              show: false,
              labels: {
                show: false,
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            }}
            maxHeight='40rem'
            aspectRatio='auto'
          />
        ) : null} */}
        <ModularMetrics />
        {props.logs ? <Logs scrollRef={scrollRef} logs={logs} /> : null}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  orgId: state.userReducer.orgId,
  logs: state.userReducer.logs,
  usersObj: state.userReducer.usersObj,
  // lineChartData: state.userReducer.lineChartData,
  authKey: state.userReducer.authKey,
  analytics: state.firebaseReducer.analytics,
  orgDetails: state.userReducer.orgDetails,
  user: state.userReducer.user,
  theme: state.userReducer.theme,
})

export default connect(mapStateToProps, {storePayload, logAnalyticsEvent})(Api)
