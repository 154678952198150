import types from '../types'
import Helpers from '../Helpers'
import endpoints from '.././endpoints.json'

export const deleteSubOrganizationLimit = (subOrgId, usageLimitId, showNotification=true) => (dispatch, getState) => {
  const userReducer = getState().userReducer
  const authKey = userReducer.authKey
  const orgId = userReducer.orgId
  const userId = userReducer.user.uid
  return Helpers.fetchDeleteJson(
      process.env.REACT_APP_METRICS_URL + endpoints.limitsUsage + `/${usageLimitId}`,
      {
        'orgId': orgId,
        'authKey': authKey,
        'userId': userId,
      },
      {},
  ).then((res) => {
    if (res?.status === 'SUCCESS') {
      if (showNotification) {
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: 'Limit Deleted Successfully',
            notificationType: 2,
            notificationIcon: 'check',
            isNotificationVisible: true,
          },
        })
      }
      return true
    } else {
      if (showNotification) {
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: 'Limit Deletion Failed',
            notificationType: 2,
            notificationIcon: 'warning',
            isNotificationVisible: true,
          },
        })
      }
      return false
    }
  }). catch((error) => {
    console.log(error)
    if (showNotification) {
      dispatch({
        type: types.STORE_PAYLOAD,
        payload: {
          userMessage: 'Limit Deletion Failed',
          notificationType: 2,
          notificationIcon: 'warning',
          isNotificationVisible: true,
        },
      })
    }
    return false
  })
}
