import types from '../types'
import Helpers from '../Helpers'
import endpoints from '.././endpoints.json'

export const getSubOrganizationLimits = (subOrgId) => (dispatch, getState) => {
  const userReducer = getState().userReducer
  const authKey = userReducer.authKey
  const orgId = userReducer.orgId
  const userId = userReducer.user.uid
  const urlParams = `?retrieveOrgId=${subOrgId}`
  Helpers.fetchGet(
      process.env.REACT_APP_METRICS_URL + endpoints.limits + urlParams,
      {
        'orgId': orgId,
        'authKey': authKey,
        'userId': userId,
      },
  ).then((res) => {
    if (res?.limits?.[subOrgId]?.usageLimits) {
      const usageLimits = res?.limits?.[subOrgId]?.usageLimits
      const limits = {}
      const modelLimitKeys = Object.keys(usageLimits || {}).sort()
      for (let mIndex = 0; mIndex < modelLimitKeys.length; mIndex++) {
        const modelId = modelLimitKeys[mIndex]
        const limitKeys = Object.keys(usageLimits?.[modelId] || {}).sort()
        for (let lIndex = 0; lIndex < limitKeys.length; lIndex++) {
          const limit = usageLimits?.[modelId]?.[limitKeys[lIndex]]
          limit['model'] = modelId
          limits[limitKeys[lIndex]] = limit
        }
      }
      dispatch({
        type: types.STORE_PAYLOAD,
        payload: {
          subOrganizationLimits: limits,
        },
      })
    } else {
      // limit call is successful, but there are no limits
      dispatch({
        type: types.STORE_PAYLOAD,
        payload: {
          subOrganizationLimits: null,
        },
      })
    }
  }). catch((error) => {
    console.log(error)
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        subOrganizationLimits: null,
        userMessage: `Error getting suborganization limits`,
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      },
    })
  })
}
