import types from '../types'
import Helpers from '../Helpers'
import {getTotalLogs} from './getTotalLogs'

export const getLogs =
  (orgId, limit = 50) =>
    async (dispatch, getState) => {
      const userReducer = getState().userReducer
      const oldLogs = userReducer.logs || []
      const authKey = userReducer.authKey
      const path = process.env.REACT_APP_METRICS_URL + `api/query/basic-logs?limit=${limit + oldLogs.length}&filter_demo_init=true&filter_indirect_calls=true`
      const headers = {
        'orgId': orgId,
        'authKey': authKey,
      }
      dispatch(getTotalLogs(orgId))
      Helpers.fetchGet(path, headers)
          .then((res) => {
            const logsObj = res?.logs
            if (logsObj) { // will not pass if the logs request fails, WILL pass if the logs are []
              dispatch({
                type: types.STORE_PAYLOAD,
                payload: {
                  logs: logsObj.map((log) => {
                    return {
                      id: log.request_id,
                      ...log,
                    }
                  }),
                },
              })
            }
          })
          .catch((err) => {
            console.log('Error: ', err)
          })
    }
