import firebase from 'firebase/compat/app'
import {connect} from 'react-redux'
import {storePayload} from '../actions/storePayload'
import React from 'react'
import {Modal} from '@lazarusai/forms-ui-components'

function AwaitingVerification(props) {
  function onClickConfirm() {
    firebase
        .auth()
        .currentUser.sendEmailVerification()
        .then(() => {
          props.storePayload({
            userMessage: 'Email sent. Please check your email',
            notificationIcon: 'check',
            notificationType: 1,
          })
        })
        .catch(() => {
          props.storePayload({
            userMessage: 'There was an error sending the email.',
            notificationIcon: 'warning',
            notificationType: 3,
          })
        })
  }

  const awaitVerificationContent = (
    <>
      <p>
        Please follow the instructions on your verification email to continue
      </p>
    </>
  )

  return (
    <div style={{fontFamily: 'Anonymous Pro'}}>
      <Modal
        content={awaitVerificationContent}
        isVisible={true}
        theme={props.theme}
        height='40vh'
        showClose={false}
        showCancel={false}
        onConfirm={onClickConfirm}
        // onCancel={onClickSkip}
        confirmText={'Resend Verification'}
        showConfirm
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  userMessage: state.userReducer.userMessage,
  theme: state.userReducer.theme,
})

export default connect(mapStateToProps, {storePayload})(AwaitingVerification)
