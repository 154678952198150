import types from '../types'
import Helpers from '../Helpers'

export const getLogData = (orgId, log) => (dispatch, getState) => {
  const userReducer = getState().userReducer
  const oldLogData = userReducer.logData
  const authKey = userReducer.authKey
  const path = process.env.REACT_APP_METRICS_URL + `api/logs/${log?.id || log?.request_id}`
  const headers = {
    'orgId': orgId,
    'authKey': authKey,
  }
  Helpers.fetchGet(path, headers)
      .then((res) => {
        const logData = res?.log
        if (res?.status === 'FAILURE') {
          console.log(res)
          // If failed, give user the basic log
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              logData: {
                ...oldLogData,
                [log.id]: log,
              },
              isGettingLogData: false,
              newLogId: log.id,
              // isGettingLogData: false,
              // userMessage: `Error pulling log data${res?.message ? ': '+res?.message : '.'}`,
              // notificationIcon: 'warning',
              // notificationType: 1,
            },
          })
        } else {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              logData: {
                ...oldLogData,
                [log.id]: logData,
              },
              isGettingLogData: false,
              newLogId: log.id,
            },
          })
        }
      })
      .catch((err) => {
        console.log('error', err)
        // dispatch({
        //   type: types.STORE_PAYLOAD,
        //   payload: {
        //     userMessage: 'There was an error pulling this log. Please contact Lazarus for assistance',
        //     notificationIcon: 'warning',
        //     notifcationType: 3,
        //   },
        // })
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            logData: {
              ...oldLogData,
              [log.id]: log,
            },
            isGettingLogData: false,
            newLogId: log.id,
            // isGettingLogData: false,
            // userMessage: `Error pulling log data${res?.message ? ': '+res?.message : '.'}`,
            // notificationIcon: 'warning',
            // notificationType: 1,
          },
        })
      })
}
