import types from '../types'
import Helpers from '../Helpers.js'
import endpoints from '.././endpoints.json'

export const getSubOrgBillingHistory = (orgId, authKey, userId, subOrgId = null, subOrgName = null) => async (dispatch) => {
  const url = process.env.REACT_APP_URL + endpoints.subOrgsBilling + '?filter_demo_init=true&filter_indirect_calls=true'
  const headers = {
    'ORGID': orgId,
    'AUTHKEY': authKey,
    'USERID': userId,
  }
  if (subOrgId) {
    headers['suborgid'] = subOrgId
  }
  dispatch({
    type: types.STORE_PAYLOAD,
    payload: {
      userMessage: `Getting billing history information...`,
      notificationIcon: 'check',
      notificationType: 2,
    },
  })
  const resp = await Helpers.fetchGetText(url, headers)
      .then((res) => {
        return res
      })
      .catch((res) => {
        console.log('error res', res)
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: `Failed to pull billing history information`,
            notificationIcon: 'warning',
            notificationType: 2,
          },
        })
        return null
      })
  if (resp) {
    Helpers.downloadFile(resp, 'text/csv', `${subOrgName ? subOrgName : 'all'}-sub-org-billing-history.csv`)
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        userMessage: null,
      },
    })
  }
  return true
}
