/* eslint-disable max-len */
import {ref, get} from 'firebase/database'

const getIndividualModelData = (database, isCustomModel, model_id, resolveReturnCallback) => (dispatch) => {
  const dbRef = ref(database, `/${isCustomModel ? 'customModels': 'models'}/${model_id}/public`)
  get(dbRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const modelPublicData = snapshot.val()
          const returnObj = {}
          returnObj[model_id] = {}
          returnObj[model_id]['public'] = modelPublicData
          resolveReturnCallback(returnObj)
        } else {
          resolveReturnCallback({})
        }
      })
      .catch((error) => {
        console.log(error)
        resolveReturnCallback({})
        dispatch(signOut())
      })
}

export const getModelInformation = (database, orgId) => async (dispatch) => {
  const modelIds = ['genericFormApi2', 'invoices', 'receipts']
  const customModelIds = ['Rikai2', 'Riky2', 'RikAI-checkboxes', 'generic-summarizer', '-NSJTaM7-nFvsGnaMegw', 'Riky', 'RikAI2-Extract', 'PII', 'PIIRedact']

  const promises = []
  for (let i = 0; i<modelIds.length; i++) {
    const model_id = modelIds[i]
    promises.push(new Promise((resolve) => {
      dispatch(getIndividualModelData(database, false, model_id, (newModelData) => {
        resolve(newModelData)
      }))
    }))
  }
  for (let i = 0; i<customModelIds.length; i++) {
    const model_id = customModelIds[i]
    promises.push(new Promise((resolve) => {
      dispatch(getIndividualModelData(database, true, model_id, (newModelData) => {
        resolve(newModelData)
      }))
    }))
  }
  const models = await Promise.all(promises)
      .then((newModelData) => {
        const filteredData = newModelData.filter((val) => {
          return Object.keys(val).length > 0
        })
        return Object.assign({}, ...filteredData)
      })
  return models
}
