import React, {useState, useEffect, useRef} from 'react'
import {Navigate} from 'react-router-dom'
import {connect} from 'react-redux'
import '../styles/Dashboard.css'
import Logs from './Logs'
import ModelUsage from './ModelUsage'
import {getLogs} from '../actions/getLogs'
import {getOrgModels} from '../actions/getOrgModels.js'
import {getMetrics} from '../actions/getMetrics'
import {logAnalyticsEvent} from '../actions/logAnalyticsEvent.js'
// import ApiUsageChart from './ApiUsageChart'
// import {getTimeStats} from '../actions/getTimeStats'
import {storePayload} from '../actions/storePayload'
// import {
// Chart,
// Card,
// MetricCard,
// Icon,
// } from '@lazarusai/forms-ui-components'

function Dashboard(props) {
  const scrollRef = useRef()
  const [isDashboardLoading, setIsDashboardLoading] = useState(true)
  // const {timeStats} = props

  useEffect(() => {
    if (props.user) {
      // getTimeStats(props.orgId, props.user.uid)
      props.logAnalyticsEvent('get_dashboard_data', {
        user_id: props.user.uid,
        hipaa_mode_on: props.orgDetails && props.orgDetails['hipaaMode'] ? props.orgDetails['hipaaMode'] : false,
        page: window.location.pathname.substring(1),
        time: Date.now(),
        user: window.navigator.userAgent,
        event_type: 'api',
      })
    }
  }, [props.user])

  useEffect(() => {
    const newIsDashboardLoading =
      isDashboardLoading &&
      props.logs &&
      props.orgModels
      // props.timeStatsData &&
      // props.lineChartData
    if (newIsDashboardLoading) {
      setIsDashboardLoading(newIsDashboardLoading)
    }
  // }, [props.logs, props.orgModels, props.timeStatsData, props.lineChartData])
  }, [props.logs, props.orgModels])

  // const failureCountToShow =
  //   !timeStats || !timeStats.failure_count ?
  //     0 :
  //     timeStats.failure_count > 1 &&
  //       timeStats.success_count / timeStats.failure_count > 100 ?
  //     timeStats.success_count * 0.01 :
  //     timeStats.failure_count

  // function chartPagesFromMetrics(logs) {
  //   const logPairs = []
  //   const logDict = {}
  //   logs.forEach((log, i) => {
  //     const pages = log['pages']
  //     const logDate =
  //   })
  // }

  if (props.logs) { // if the logs are loaded in [] or with logs
    props.storePayload({
      isLoading: false,
    })
    return (
      <div id='dashboard-div'>
        {props.logs?.length > 0 ? // only render page if there are logs - else redirect
          <div className='dashboard-left show-scrollbar' ref={scrollRef}>
            {/* <ApiUsageChart
              data={props.lineChartData}
              xAxis={{
                type: 'datetime',
              }}
              aspectRatio='auto'
            /> */}
            {/* <Card className='requests-graph' theme={props.theme}>
              <span className='card-title'>Requests over last 30 days</span>
              <div className='requests-graph-content flex'>
                <div className='donut-chart-container'>
                  <Chart
                    data={[timeStats.success_count, failureCountToShow]}
                    type='pie'
                    theme={props.theme}
                    maxHeight='100%'
                    aspectRatio='auto'
                  />
                </div>
                <div className='donut-chart-data-container'>
                  <div className='total-container'>
                    <div className='dash background-bright-blue' />
                    <p className='text-xl text-bright-blue'>
                      {timeStats.request_count}
                    </p>
                    <p className=''>Total</p>
                  </div>
                  <div className='flex'>
                    <div className='chart-stat-container'>
                      <div className='dash background-bright-green' />
                      <p className='text-xl text-bright-green'>
                        {timeStats.success_count}
                      </p>
                      <p className=''>Successes</p>
                    </div>
                    <div className='chart-stat-container'>
                      <div className='dash background-red' />
                      <p className='text-xl text-red'>
                        {timeStats.failure_count}
                      </p>
                      <p className=''>Failures</p>
                    </div>
                  </div>
                </div>
              </div>
            </Card> */}
            {/* <MetricCard
              title='Requests This Month'
              className='mdc-1'
              icon={<Icon icon='repeat-outline' />}
              value={timeStats.month_requests}
              column1={timeStats.day_requests}
              column2='Today'
            />
            <MetricCard
              title='Pages This Month'
              color='metallic-blue'
              className='mdc-2'
              value={timeStats.month_pages}
              column1={timeStats.day_pages}
              column2='Today'
            />
            <MetricCard
              title='Errors This Month'
              color='french-puce'
              className='mdc-3'
              icon={<Icon icon='alert-triangle-outline' />}
              value={timeStats.month_failures}
              column1={timeStats.day_failures}
              column2='Today'
            /> */}
            <div className='org-tables'>
              {props.logs && (
                <div className='logs'>
                  <Logs scrollRef={null} logs={props.logs.slice(0, 50)}/>
                </div>
              )}
              {props.orgModelMetrics?.[props.orgId] && (
                <div className='models'>
                  <ModelUsage
                    models={structuredClone(props.orgModelMetrics)}
                  />
                </div>
              )}
            </div>
          </div> :
          <Navigate to='/quickstart' />

        }
        {/* <div className='dashboard-right'>
          <div>
            <MetricCard
              title='Total Requests'
              icon={<Icon icon='repeat-outline' />}
              value={timeStats.total_requests}
              column1={timeStats.average_requests}
              column2='Average/Month'
            />
            <MetricCard
              title='Total Pages'
              color='metallic-blue'
              value={timeStats.total_pages}
              column1={timeStats.average_pages}
              column2='Average/Month'
            />
          </div>
        </div> */}
      </div>
    )
  } else {
    props.storePayload({
      isLoading: true,
    })
    return <></>
  }
}

const mapStateToProps = (state, ownProps) => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  orgId: state.userReducer.orgId,
  user: state.userReducer.user,
  logs: state.userReducer.logs,
  orgModels: state.userReducer.orgModels,
  orgModelMetrics: state.userReducer.orgModelMetrics,
  theme: state.userReducer.theme,
  // timeStats: state.userReducer.timeStats,
  // lineChartData: state.userReducer.lineChartData,
  orgDetails: state.userReducer.orgDetails,
  analytics: state.firebaseReducer.analytics,
})

export default connect(
    mapStateToProps,
    {getLogs, getOrgModels, getMetrics, logAnalyticsEvent, storePayload},
)(Dashboard)
