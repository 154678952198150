import React, {useState, useEffect} from 'react'
import {Button, Icon, Input, Loader, Select} from '@lazarusai/forms-ui-components'
import {connect} from 'react-redux'
import {storePayload} from '../actions/storePayload.js'
import {getLogData} from '../actions/getLogData.js'
import {logAnalyticsEvent} from '../actions/logAnalyticsEvent'
import '../styles/Logs.css'
import '../styles/Table.css'
import '../styles/ModularMetrics.css'
import Helpers from '../Helpers.js'

function ModelUsage({
  alternativeModelOptions=null,
  metricOrgId=null,
  metricAuthKey=null,
  ...props
}) {
  const [modelOptions, setModelOptions] = useState([])
  const [selectedModels, setSelectedModels] = useState([])
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [isMetricsLoading, setIsMetricsLoading] = useState(false)

  useEffect(() => {
    if (alternativeModelOptions) {
      setModelOptions(['All', ...alternativeModelOptions.
          map((initialModelId) => {
            const hasPIIAccess = (alternativeModelOptions).includes('PII')
            const isRedact = initialModelId.includes('Redact')
            const modelId = (!hasPIIAccess && isRedact) ? 'PII': initialModelId
            const hasModelInformation = props.modelInformation?.[modelId]?.public
            const isUpdatedRedact = modelId.includes('Redact')
            if (isUpdatedRedact || !hasModelInformation) {
              return null
            } else {
              return `${props.modelInformation?.[modelId]?.public?.metadata?.name}${
                (modelId === 'PII') ? ` & ${props.modelInformation?.['PIIRedact']?.public?.metadata?.name}` : ''
              } - ${modelId}`
            }
          }).filter((modelString) => modelString !== null)])
    } else if (props.modelAccess) {
      setModelOptions(['All', ...props.modelAccess
          .map((initialModelId) => {
            const hasPIIAccess = (props.modelAccess).includes('PII')
            const isRedact = initialModelId.includes('Redact')
            const modelId = (!hasPIIAccess && isRedact) ? 'PII': initialModelId
            const hasModelInformation = props.modelInformation?.[modelId]?.public
            const isUpdatedRedact = modelId.includes('Redact')
            if (isUpdatedRedact || !hasModelInformation) {
              return null
            } else {
              return `${props.modelInformation?.[modelId]?.public?.metadata?.name}${
                (modelId === 'PII') ? ` & ${props.modelInformation?.['PIIRedact']?.public?.metadata?.name}` : ''
              } - ${modelId}`
            }
          }).filter((modelString) => modelString !== null)])
    } else {
      setModelOptions([])
    }
  }, [props.modelAccess, alternativeModelOptions])

  useEffect(() => {
    const nowTS = Date.now()
    const timeZoneOffset = (new Date()).getTimezoneOffset() * 60000
    setStartTime(new Date(nowTS - (30 * 24 * 60 * 60 * 1000) - timeZoneOffset).toISOString().slice(0, 16))
    setEndTime(new Date(nowTS - timeZoneOffset).toISOString().slice(0, 16))
  }, [])

  function adjustTimestamp(timestamp) {
    try {
      const timeZoneOffset = (new Date()).getTimezoneOffset() * 60000
      return new Date(timestamp * 1000 - timeZoneOffset).toLocaleString('en-US', {timeZone: 'UTC'})
    } catch (error) {
      console.log(error)
      return 'N/A'
    }
  }

  function onClickModelOption(optionName) {
    let newModels = []
    if (optionName === 'All') {
      if (selectedModels?.filter((val) => val !== 'All')?.length === modelOptions?.filter((val) => val !== 'All')?.length) {
        newModels = []
      } else {
        newModels = structuredClone(modelOptions)
      }
    } else if (selectedModels.includes(optionName)) {
      newModels = selectedModels.filter((val) => val !== optionName)
    } else {
      newModels = [...selectedModels, optionName]
    }
    if (newModels?.filter((val) => val !== 'All')?.length === modelOptions?.filter((val) => val !== 'All')?.length) {
      newModels = [...newModels?.filter((val) => val !== 'All'), 'All']
    } else {
      newModels = newModels?.filter((val) => val !== 'All')
    }
    setSelectedModels(newModels)
  }

  function onSubmit() {
    const cleanedModels = selectedModels.filter((val) => val !== 'All').map((val) => val.split(' - ')[1])
    const hasInvoices = cleanedModels.includes('invoices')
    if (hasInvoices) {
      cleanedModels.push('invoices-with-kvp')
    }
    const urlBase = process.env.REACT_APP_METRICS_URL + 'api/query/models/time-period'
    const urlParams = `?filter_demo_init=true&filter_indirect_calls=true&start_time=${Date.parse(startTime)}&end_time=${Date.parse(endTime)}&models=${cleanedModels.join(',')}`
    const fullUrl = encodeURI(`${urlBase}${urlParams}`)
    setIsMetricsLoading(true)
    Helpers.fetchGet(fullUrl, {
      orgId: metricOrgId || props.orgId,
      authKey: metricAuthKey || props.authKey,
    }).then((resp) => {
      const metricsFeed = structuredClone(props.orgModularMetricsFeed?.[metricOrgId || props.orgId] || [])
      if (hasInvoices) {
        let invoicesStats = {
          'model_id': '',
          'model_name': '',
          'timestamp': '',
          'requests': 0,
          'successes': 0,
          'failures': 0,
          'pages': 0,
          'questions': 0,
        }
        let invoicesWithKVPStats = {
          'model_id': '',
          'model_name': '',
          'timestamp': '',
          'requests': 0,
          'successes': 0,
          'failures': 0,
          'pages': 0,
          'questions': 0,
        }
        let hasInvoiceData = false
        const models = resp.models.filter((model) => {
          if (model?.model_id === 'invoices') {
            invoicesStats = structuredClone(model)
            hasInvoiceData = true
            return false
          } else if (model?.model_id === 'invoices-with-kvp') {
            invoicesWithKVPStats = structuredClone(model)
            hasInvoiceData = true
            return false
          }
          return model?.model_id?.length
        })
        // Combine Invoices models - parseInt is to avoid undefined values for one model or the other
        if (hasInvoiceData) {
          models.push({
            'model_id': 'invoices',
            'model_name': 'invoices',
            'timestamp': invoicesStats?.['timestamp'] > invoicesWithKVPStats?.['timestamp'] ? invoicesStats?.['timestamp']: invoicesWithKVPStats?.['timestamp'],
            'requests': parseInt(invoicesStats?.['requests'] || 0) + parseInt(invoicesWithKVPStats?.['requests'] || 0),
            'successes': parseInt(invoicesStats?.['successes'] || 0) + parseInt(invoicesWithKVPStats?.['successes'] || 0),
            'failures': parseInt(invoicesStats?.['failures'] || 0) + parseInt(invoicesWithKVPStats?.['failures'] || 0),
            'pages': parseInt(invoicesStats?.['pages'] || 0) + parseInt(invoicesWithKVPStats?.['pages'] || 0),
            'questions': parseInt(invoicesStats?.['questions'] || 0) + parseInt(invoicesWithKVPStats?.['questions'] || 0),
          })
        }
        resp.models = structuredClone(models)
      }
      metricsFeed.push({
        inputs: {
          models: cleanedModels.filter((modelId) => modelId !== 'invoices-with-kvp'),
          startTime: startTime,
          endTime: endTime,
        },
        outputs: resp,
      })
      const newMetricsFeed = structuredClone(props.orgModularMetricsFeed)
      newMetricsFeed[metricOrgId || props.orgId] = metricsFeed
      props.storePayload({
        orgModularMetricsFeed: newMetricsFeed,
      })
      setIsMetricsLoading(false)
    }).catch((err) => {
      console.log(err)
      props.storePayload({
        userMessage: 'Error generating the intended metrics',
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      })
      setIsMetricsLoading(false)
    })
  }

  return (
    <div
      className='modular-metrics-card'
    >
      <div
        className='modular-metrics-side modular-metrics-input'
      >
        <h4>
          Model Usage by Period
        </h4>
        <Select
          theme={props.theme}
          label={'Models'}
          onClickOption={onClickModelOption}
          options={modelOptions}
          value={selectedModels}
          isSelectedYellow={true}
          textMaxLength={30}
          isMultiselect={true}
        />
        <Input
          theme={props.theme}
          type={1}
          inputType={'datetime-local'}
          label={'Start date and time'}
          value={startTime}
          onChange={(e) => {
            setStartTime(e.target.value)
          }}
          style={{cursor: 'pointer'}}
        />
        <Input
          theme={props.theme}
          type={1}
          inputType={'datetime-local'}
          label={'End date and time'}
          value={endTime}
          onChange={(e) => {
            setEndTime(e.target.value)
          }}
          style={{cursor: 'pointer'}}
        />
        <Button
          theme={props.theme}
          text={'Get Usage'}
          iconPosition='right'
          iconJustify='edge'
          icon={
            <Icon
              icon={'arrow-forward-outline'}
            />
          }
          onClick={onSubmit}
          disabled={selectedModels?.length <= 0}
        />
      </div>
      <div
        className='modular-metrics-side modular-metrics-output'
      >
        {isMetricsLoading &&
          <div
            className='loader-div'
          >
            <Loader
              theme={props.theme}
            />
          </div>
        }
        <div
          className='output-list'
        >
          {structuredClone((props.orgModularMetricsFeed?.[metricOrgId || props.orgId] || [])).reverse().map((metricResponse, rIndex) => {
            const responseModels = metricResponse?.inputs?.models
            const responseStartTime = metricResponse?.inputs?.startTime
            const responseEndTime = metricResponse?.inputs?.endTime
            const responseOutputModels = metricResponse?.outputs?.models?.filter((modelInfo) => modelInfo?.model_id !== undefined)
            return (
              <div
                className='output-card'
                key={`${JSON.stringify(responseModels)}-${rIndex}`}
              >
                <div
                  className='action-section'
                >
                  <div
                    className='action-item'
                    onClick={() => {
                      Helpers.downloadFile(JSON.stringify(metricResponse, null, 2), 'application/json', `${(metricOrgId || props.orgId)}-${new Date(responseStartTime).toLocaleString('en-US')}-${new Date(responseEndTime).toLocaleString('en-US')}-${responseModels.map((modelId) => props.modelInformation?.[modelId]?.public?.metadata?.name).join(', ')}.json`)
                    }}
                  >
                    <Icon
                      icon={'download-outline'}
                    />
                  </div>
                </div>
                <div
                  className='time-title'
                >
                  {`${new Date(responseStartTime).toLocaleString('en-US')} - ${new Date(responseEndTime).toLocaleString('en-US')}`}
                </div>
                <div
                  className='model-list'
                >
                  {(!responseModels || responseModels?.length === 0) && <p>No usage over this period</p>}
                  {responseModels.map((modelId) => props.modelInformation?.[modelId]?.public?.metadata?.name).join(', ')}
                </div>
                <>
                  {(!responseOutputModels || responseOutputModels?.length === 0) && <p>No usage over this period</p>}
                  {(responseOutputModels || []).map((modelInfo) => {
                    const modelStats = ['model_id', 'timestamp', 'successes', 'failures', 'pages', 'requests', 'questions']
                    return (
                      <div
                        key={`${modelInfo['model_id']}-group`}
                        className='output-body'
                      >
                        {modelStats.map((stat) => {
                          return (
                            <div
                              key={`${stat}-${modelInfo['model_id']}`}
                              className={`body-kvp ${stat === 'model_id' ? 'model-kvp' : stat === 'timestamp' ? 'full-kvp': ''}`}
                            >
                              <div
                                className='body-key'
                              >
                                {Helpers.mysteryCaseToDisplay(stat === 'timestamp' ? 'last_use' : stat)}
                              </div>
                              <div
                                className='body-value'
                              >
                                {stat === 'timestamp' ? adjustTimestamp(modelInfo?.[stat]) : stat === 'model_id' ? props.modelInformation?.[modelInfo?.[stat]?.replace('invoices-with-kvp', 'invoices')]?.public?.metadata?.name : modelInfo?.[stat]}
                              </div>
                            </div>
                          )
                        })
                        }
                      </div>
                    )
                  })}
                </>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  orgId: state.userReducer.orgId,
  authKey: state.userReducer.authKey,
  theme: state.userReducer.theme,
  modelInformation: state.userReducer.modelInformation,
  modelAccess: state.userReducer.modelAccess,
  orgModularMetricsFeed: state.userReducer.orgModularMetricsFeed,
})

export default connect(mapStateToProps, {
  getLogData,
  storePayload,
  logAnalyticsEvent,
})(ModelUsage)
