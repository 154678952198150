// Dependencies
import React from 'react'

// Styles
import '../styles/FullLoadingScreen.css'

// Components
import {
  Animation,
  // Loader,
} from '@lazarusai/forms-ui-components'

export default function FullLoadingScreen(props) {
  return (
    <div className='full-loading-screen backdrop'>
      {/* <Loader theme={props.theme} /> */}
      <Animation
        animationState={'generating'}
        stopAnimation={false}
        height={'50vh'}
        width={'50vw'}
      />
    </div>
  )
}
